import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSearch,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import "./UserOrder.css";

interface User {
  fullName: string;
  email: string;
  phoneNumber: string;
  profileImageUrl: string;
  about: string;
  accountType: string;
  dateOfBirth: string;
  gender: string;
  points: number;
  rating: string;
}

interface Order {
  id: string;
  locationName: string;
  paymentStatus: string;
  status: string;
  datetime: string;
  totalOrderPrice: number;
  installerName?: string;
  installerImage?: string;
}

const UserOrders: React.FC = () => {
  const { userId } = useParams();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();

  // ✅ **جلب بيانات المستخدم**
  const fetchUserInfo = async () => {
    try {
      const userDocRef = doc(db, "users", userId as string);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setUserInfo(userDoc.data() as User);
      }
    } catch (error) {
      console.error("❌ Error fetching user info:", error);
    }
  };

  // ✅ **جلب جميع الطلبات الخاصة بالمستخدم**
  const fetchUserOrders = async () => {
    setLoading(true);
    try {
      const completeOrderCollection = collection(
        db,
        "Order",
        userId as string,
        "CompleteOrder"
      );
      const completeOrderDocs = await getDocs(completeOrderCollection);

      const fetchedOrders: Order[] = [];

      for (const docSnapshot of completeOrderDocs.docs) {
        const data = docSnapshot.data();
        let installerName = "غير معروف";
        let installerImage = "";

        // ✅ **جلب بيانات الفني إذا كان موجودًا**
        if (data.InstallerId) {
          const installerDocRef = doc(db, "users", data.InstallerId);
          const installerDoc = await getDoc(installerDocRef);
          if (installerDoc.exists()) {
            const installerData = installerDoc.data();
            installerName = installerData.fullName || "غير معروف";
            installerImage = installerData.profileImageUrl || "";
          }
        }

        fetchedOrders.push({
          id: docSnapshot.id,
          locationName: data.locationName || "غير متوفر",
          paymentStatus: data.PaymentStutes || "غير متوفر",
          status: data.Stutes || "غير متوفر",
          datetime: data.datetime
            ? new Date(data.datetime.seconds * 1000).toLocaleString()
            : "غير متوفر",
          totalOrderPrice: data.totalOrderPrice || 0,
          installerName,
          installerImage,
        });
      }

      setOrders(fetchedOrders);
      setLoading(false);
    } catch (error) {
      console.error("❌ Error fetching user orders:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserInfo();
    fetchUserOrders();
  }, [userId]);

  return (
    <div className="product-container">
      {/* ✅ **شريط البحث وزر الرجوع** */}

      {/* ✅ **عرض بيانات المستخدم مع أيقونة الرجوع** */}
      {userInfo && (
        <div className="user-info-card">
          {/* 🔹 أيقونة الرجوع في الزاوية العلوية اليسرى */}
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="back-icon"
            onClick={() => navigate(-1)}
            title="الرجوع"
          />

          <div className="user-info flex-container">
            {/* 🔹 **الصورة على اليسار** */}
            <div className="user-image-container">
              <img
                src={userInfo.profileImageUrl}
                alt={userInfo.fullName}
                className="user-image"
              />
            </div>

            {/* 🔹 **المعلومات على اليمين** */}
            <div className="user-details">
              <h2 style={{ fontFamily: "Tajawal, sans-serif" }}>
                <label style={{ fontFamily: "Tajawal, sans-serif" }}>
                  {userInfo.fullName}
                </label>
              </h2>
              <p>
                <strong style={{ fontFamily: "Tajawal, sans-serif" }}>
                  البريد الإلكتروني:
                </strong>
                <label style={{ fontFamily: "Tajawal, sans-serif" }}>
                  {userInfo.email}
                </label>
              </p>
              <p>
                <strong style={{ fontFamily: "Tajawal, sans-serif" }}>
                  رقم الهاتف:
                </strong>
                <label style={{ fontFamily: "Tajawal, sans-serif" }}>
                  {userInfo.phoneNumber}
                </label>
              </p>
              <p>
                <strong style={{ fontFamily: "Tajawal, sans-serif" }}>
                  النوع:
                </strong>
                <label style={{ fontFamily: "Tajawal, sans-serif" }}>
                  {userInfo.gender === "male" ? "ذكر" : "أنثى"}
                </label>
              </p>
              <p>
                <strong style={{ fontFamily: "Tajawal, sans-serif" }}>
                  <label style={{ fontFamily: "Tajawal, sans-serif" }}>
                    تاريخ الميلاد:
                  </label>
                </strong>
                {userInfo.dateOfBirth}
              </p>
              <p>
                <strong style={{ fontFamily: "Tajawal, sans-serif" }}>
                  نوع الحساب:
                </strong>
                <label style={{ fontFamily: "Tajawal, sans-serif" }}>
                  {userInfo.accountType}
                </label>
              </p>
              <p>
                <strong style={{ fontFamily: "Tajawal, sans-serif" }}>
                  النقاط:
                </strong>
                <label style={{ fontFamily: "Tajawal, sans-serif" }}>
                  {userInfo.points}
                </label>
              </p>
              <p>
                <strong style={{ fontFamily: "Tajawal, sans-serif" }}>
                  التقييم:
                </strong>
                <label style={{ fontFamily: "Tajawal, sans-serif" }}>
                  {userInfo.rating} ⭐
                </label>
              </p>
              <p>
                <strong style={{ fontFamily: "Tajawal, sans-serif" }}>
                  نبذة:
                </strong>
                <label style={{ fontFamily: "Tajawal, sans-serif" }}>
                  {userInfo.about}
                </label>
              </p>
            </div>
          </div>
        </div>
      )}

      <h2
        className="text-center text-lg font-bold mb-4"
        style={{ fontFamily: "Tajawal, sans-serif" }}
      >
        قائمة الطلبات الخاصة بـ {userInfo?.fullName}
      </h2>

      {/* ✅ **عرض الطلبات داخل جدول** */}
      {loading ? (
        <p className="text-center">جاري تحميل البيانات...</p>
      ) : orders.length === 0 ? (
        <p className="text-center">لا يوجد طلبات لهذا العميل.</p>
      ) : (
        <div className="table-container">
          <table className="product-table">
            <thead className="color-table">
              <tr>
                <th>#</th>
                <th>صورة الفني</th>
                <th>اسم الفني</th>
                <th>الموقع</th>
                <th>حالة الدفع</th>
                <th>حالة الطلب</th>
                <th>التاريخ</th>
                <th>إجمالي السعر</th>
                <th>التفاصيل</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={order.id}>
                  <td>{index + 1}</td>
                  <td>
                    {order.installerImage ? (
                      <img
                        src={order.installerImage}
                        alt={order.installerName}
                        className="product-image"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    ) : (
                      "لا يوجد صورة"
                    )}
                  </td>
                  <td>{order.installerName}</td>
                  <td>{order.locationName}</td>
                  <td>{order.paymentStatus}</td>
                  <td>{order.status}</td>
                  <td>{order.datetime}</td>
                  <td>{order.totalOrderPrice} $</td>
                  <td>
                    <div className="icon-container">
                      <button
                        className="icon-button"
                        onClick={() =>
                          navigate(
                            `/dashboard/user-orders/${userId}/${order.id}`
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faEye} title="عرض الطلبات" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UserOrders;
