import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const EditFacet: React.FC = () => {
  const { id } = useParams(); // جلب ID الفئة من الرابط
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  // ✅ **جلب بيانات الفئة من Firestore**
  useEffect(() => {
    const fetchFacet = async () => {
      if (!id) return;
      try {
        const facetRef = doc(db, "facets", id);
        const facetSnap = await getDoc(facetRef);

        if (facetSnap.exists()) {
          setName(facetSnap.data().name);
        } else {
          toast.error("الفئة غير موجودة!");
          navigate("/dashboard/facets");
        }
      } catch (error) {
        console.error("خطأ في جلب البيانات:", error);
        toast.error("حدث خطأ أثناء جلب البيانات!");
      }
    };

    fetchFacet();
  }, [id, navigate]);

  // ✅ **تحديث بيانات الفئة في Firestore**
  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name.trim()) {
      toast.error("يرجى إدخال اسم الفئة!", {
        duration: 3000,
        position: "top-right",
        style: { backgroundColor: "#ff4d4f", color: "#fff" },
      });
      return;
    }

    setLoading(true);
    try {
      const facetRef = doc(db, "facets", id as string);
      await updateDoc(facetRef, { name });

      toast.success("تم تحديث الفئة بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: { backgroundColor: "#4CAF50", color: "#fff" },
      });

      navigate("/dashboard/facets"); // ✅ العودة إلى قائمة الفئات
    } catch (error) {
      console.error("خطأ في تحديث البيانات:", error);
      toast.error("حدث خطأ أثناء التحديث، حاول مرة أخرى!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto my-10 bg-white p-8 rounded-xl shadow-lg">
      <h1 className="text-2xl font-bold text-gray-700 text-center mb-6">
        تعديل الفئة
      </h1>
      <form onSubmit={handleUpdate} className="space-y-6">
        {/* حقل إدخال الاسم */}
        <div>
          <label className="block text-gray-600 font-semibold mb-2">
            اسم الفئة:
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* زر الحفظ */}
        <div className="flex justify-between">
          <button
            type="button"
            className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500"
            onClick={() => navigate("/dashboard/facets")}
          >
            إلغاء
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition"
          >
            {loading ? "جاري التحديث..." : "تحديث الفئة"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditFacet;
