// import React from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Navigate,
//   useNavigate,
// } from "react-router-dom";

// import Login from "./screen/Login";
// import Dashboard from "./screen/Dashboard";
// import Product from "./screen/Product";
// import AddProduct from "./screen/AddProduct"; // استيراد الواجهة الجديدة
// import EditProduct from "./screen/EditProduct";
// import Account from "./screen/Account";
// import Home from "./screen/Home";
// import AddSerial from "./screen/AddSerial";
// import AddTags from "./screen/AddTags";
// import Tags from "./screen/Tags";
// import EditTag from "./screen/EditTag";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { Toaster } from "react-hot-toast";
// import { SidebarProvider } from "./contexts/SidebarContext";
// import { useState, useEffect } from "react";
// import Sidebar from "./layouts/Sidebar";
// import Layout from "./layouts/Layout";
// import "./index.css";
// import "./App.css";
// const theme = createTheme({
//   typography: {
//     fontFamily: "Tajawal, Arial",
//   },
// });
// const App: React.FC = () => {
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
//   const [loading, setLoading] = useState<boolean>(true);
//   const navigate = useNavigate();

//   // ✅ **التحقق من حالة تسجيل الدخول عند تحميل التطبيق**

//   // ✅ **دالة تسجيل الخروج**

//   // ✅ **دالة تسجيل الدخول**
//   const handleLogin = (authToken: string) => {
//     setIsAuthenticated(true);
//     console.log("تم تسجيل الدخول بنجاح! ✅");

//     // ✅ بعد تحديث الحالة، انتقل إلى Dashboard
//     navigate("/");
//   };

//   // ✅ **دالة تسجيل الخروج**
//   const handleLogout = () => {
//     setIsAuthenticated(false);
//   };

//   // ✅ **عرض شاشة تحميل أثناء التحقق من حالة تسجيل الدخول**
//   if (loading) {
//     return (
//       <div className="flex justify-center items-center h-screen">
//         جاري التحقق...
//       </div>
//     );
//   }
//   return (
//     <ThemeProvider theme={theme}>
//       <Toaster />
//       <SidebarProvider>
//         {isAuthenticated ? (
//           // ✅ **إذا كان المستخدم مسجلاً، يتم عرض النظام بالكامل**
//           <Router>
//             <div className="flex">
//               <Sidebar onLogout={handleLogout} />
//               <Layout>
//                 <Routes>
//                   <Route path="/" element={<Dashboard />} />
//                   <Route path="*" element={<Navigate to="/" />} />
//                 </Routes>
//               </Layout>
//             </div>
//           </Router>
//         ) : (
//           <Router>
//             <Routes>
//               <Route path="*" element={<Navigate to="/Login" />} />
//               <Route path="/login" element={<Login onLogin={handleLogin} />} />
//             </Routes>
//           </Router>
//         )}
//       </SidebarProvider>
//     </ThemeProvider>
//   );
// };

// export default App;

import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { SidebarProvider } from "./contexts/SidebarContext";
import AppRoutes from "./routes/AppRoutes"; // 🔥 استدعاء ملف التوجيه
import "./index.css";
import "./App.css";

const theme = createTheme({
  typography: {
    fontFamily: "Tajawal, Arial",
  },
});

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  // ✅ دالة تسجيل الدخول ✅
  const handleLogin = (authToken: string) => {
    setIsAuthenticated(true);
    console.log("تم تسجيل الدخول بنجاح! ✅");
  };

  // ✅ دالة تسجيل الخروج ✅
  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Toaster />
      <SidebarProvider>
        {/* 🔥 تمرير `isAuthenticated` إلى `AppRoutes` */}
        <AppRoutes
          isAuthenticated={isAuthenticated}
          onLogin={handleLogin}
          onLogout={handleLogout}
        />
      </SidebarProvider>
    </ThemeProvider>
  );
};

export default App;
