import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";

import { db } from "../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  setDoc,
  writeBatch,
  addDoc,
  query,
  where,
  orderBy,
  limit,
  QueryDocumentSnapshot,
  DocumentData,
  startAfter,
  deleteDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrash,
  faSearch,
  faPlus,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";

interface Tag {
  id: string;
  name: string;
  imageUrl: string;
}

// ✅ تعريف نوع المودال Props
interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
const EditProduct: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [product, setProduct] = useState<any>(null);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [tags, setTags] = useState<any[]>([]); // لتخزين العلامات
  const [selectedTag, setSelectedTag] = useState<string>(""); // العلامة المحددة
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const storage = getStorage();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [mainImage, setMainImage] = useState<File | null>(null);
  const [mainImageUrl, setMainImageUrl] = useState<string | null>(null);
  const [extraImages, setExtraImages] = useState<File[]>([]);
  const [extraPreviews, setExtraPreviews] = useState<string[]>([]);
  const [storedExtraImages, setStoredExtraImages] = useState<string[]>([]);
  const [serialNumbers, setSerialNumbers] = useState<
    { id: string; point: number }[]
  >([]);
  const [isdeleteSerailModalOpen, setisdeleteSerailModalOpen] = useState(false);
  const [serialToDelete, setSerialToDelete] = useState<string | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteImageModalOpen, setisDeleteImageModalOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<string | File | null>(
    null
  );
  const [isFromFirestore, setIsFromFirestore] = useState(false);

  const PAGE_SIZE = 100; // عدد العناصر التي يتم تحميلها في كل مرة
  const filteredSerialNumbers = serialNumbers.filter((serial) =>
    serial.id.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false); // حالة تحميل البيانات
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setExcelFile(file);
      console.log("📂 تم تحديد الملف:", file.name);
    }
  };

  const handleUploadSerials = async () => {
    if (!excelFile) {
      alert("⚠️ يرجى تحديد ملف Excel أولا!");
      return;
    }

    if (!id || !product) {
      alert("⚠️ لا يوجد معرف للمنتج أو بيانات المنتج مفقودة!");
      return;
    }

    setIsUploading(true);

    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target?.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // اختيار الورقة الأولى
        const sheet = workbook.Sheets[sheetName];

        const serialNumbers: any[] = XLSX.utils.sheet_to_json(sheet);

        // 🔹 التحقق من وجود بيانات
        if (serialNumbers.length === 0) {
          alert("⚠️ الملف لا يحتوي على بيانات!");
          setIsUploading(false);
          return;
        }

        // 🔹 رفع البيانات إلى Firestore دفعة واحدة
        for (const serial of serialNumbers) {
          const serialId = serial.SerialNumber?.toString().trim(); // تأكد من أن الرقم بصيغة `string`
          if (!serialId) continue; // تخطي القيم الفارغة

          const serialRef = doc(db, "SerailNumber", serialId); // استخدام SerialNumber كـ ID

          await setDoc(serialRef, {
            point: product.points, // 🔹 استخدام `product.points`
            productId: id, // 🔹 إضافة `productId`
          });
        }

        toast.success("✅ تم اضافة سيريلات جديدة المنتج بنجاح!", {
          duration: 3000,
          position: "top-right",
          style: {
            background:
              "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
            color: "#fff",
            fontSize: "16px",
          },
        });
        setIsUploading(false);
        setExcelFile(null);
      };

      reader.readAsBinaryString(excelFile);
    } catch (error) {
      console.error("❌ خطأ في رفع البيانات:", error);
      alert("❌ حدث خطأ أثناء رفع البيانات!");
      setIsUploading(false);
    }
  };

  // Fetch product data
  const fetchProduct = async () => {
    try {
      const productRef = doc(db, "product", id!);
      const productSnap = await getDoc(productRef);

      if (productSnap.exists()) {
        const productData = productSnap.data();
        setProduct({ id: productSnap.id, ...productData });

        // ✅ تحميل الصورة الرئيسية
        if (productData.imageUrl) {
          setMainImageUrl(productData.imageUrl);
        }

        // ✅ تحميل الصور الإضافية
        const imagesCollection = collection(
          db,
          "product",
          id!,
          "productImages"
        );
        const imagesSnapshot = await getDocs(imagesCollection);
        const imageUrls = imagesSnapshot.docs.map((doc) => doc.data().imageUrl);
        setStoredExtraImages(imageUrls);
      } else {
        alert("المنتج غير موجود");
        navigate("/dashboard/home");
      }
    } catch (error) {
      console.error("❌ خطأ في تحميل المنتج:", error);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [id]);

  useEffect(() => {
    const fetchTags = async () => {
      const tagsCollection = collection(db, "tags");
      const tagsSnapshot = await getDocs(tagsCollection);
      const fetchedTags = tagsSnapshot.docs.map((doc) => ({
        id: doc.id, // ✅ تعيين ID بشكل منفصل
        name: doc.data().name, // ✅ تجنب الانتشار العشوائي
        imageUrl: doc.data().ImageUrl,
      }));
      setTags(fetchedTags);
    };

    fetchTags();
  }, []);

  useEffect(() => {
    const fetchProductTags = async () => {
      try {
        if (!id) return;

        // ✅ جلب التاكات المحفوظة داخل المنتج `product/{id}/tags`
        const productTagsCollection = collection(db, "product", id, "tags");
        const productTagsSnapshot = await getDocs(productTagsCollection);
        const savedTags = productTagsSnapshot.docs.map((doc) => doc.id);

        setSelectedTags(savedTags);
      } catch (error) {
        console.error("❌ خطأ في جلب التاكات الخاصة بالمادة:", error);
      }
    };

    fetchProductTags();
  }, [id]);
  const toggleTagSelection = (tagId: string) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tagId)
        ? prevTags.filter((id) => id !== tagId)
        : [...prevTags, tagId]
    );
  };

  const fetchSerialNumbers = async (
    lastDoc?: QueryDocumentSnapshot<DocumentData>
  ) => {
    try {
      const serialNumberCollection = collection(db, "SerailNumber");

      // 🔎 إنشاء الاستعلام باستخدام `startAfter` فقط إذا كان `lastDoc` موجودًا
      let serialQuery = query(
        serialNumberCollection,
        where("productId", "==", id),
        limit(PAGE_SIZE)
      );

      if (lastDoc) {
        serialQuery = query(serialQuery, startAfter(lastDoc));
      }

      const serialSnapshot = await getDocs(serialQuery);

      if (!serialSnapshot.empty) {
        const fetchedSerials = serialSnapshot.docs.map((doc) => ({
          id: doc.id,
          point: doc.data().point,
        }));

        setSerialNumbers((prevSerials) => [...prevSerials, ...fetchedSerials]);

        // ✅ تعيين `lastVisible` للوثيقة الأخيرة
        setLastVisible(serialSnapshot.docs[serialSnapshot.docs.length - 1]);
      } else {
        setLastVisible(null); // لا يوجد مزيد من البيانات
      }
    } catch (error) {
      console.error("❌ خطأ في جلب البيانات:", error);
    }
  };

  useEffect(() => {
    fetchProduct();
    fetchSerialNumbers();
  }, [id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!id || !product) {
      alert("⚠️ لا يوجد معرف للمنتج أو بيانات المنتج مفقودة!");
      return;
    }

    setLoading(true);

    try {
      // 🔹 مرجع إلى الوثيقة الخاصة بالمنتج في Firestore
      const productRef = doc(db, "product", id);

      // 🔹 تحديث البيانات
      await updateDoc(productRef, {
        code: product.code,
        name: product.name,
        points: product.points,
        location: product.location,
        price: product.price,
        rating: product.rating,
        Quantity: product.Quantity,
        productDescription: product.productDescription,
      });

      // 🔹 تحديث التاكات المرتبطة بالمنتج
      const tagsCollectionRef = collection(db, "product", id, "tags");

      // ✅ حذف جميع التاكات السابقة
      const existingTagsSnapshot = await getDocs(tagsCollectionRef);
      existingTagsSnapshot.forEach(async (tagDoc) => {
        await deleteDoc(doc(tagsCollectionRef, tagDoc.id));
      });

      // ✅ إضافة التاكات الجديدة المحددة
      for (const tagId of selectedTags) {
        await setDoc(doc(tagsCollectionRef, tagId), { tagId });
      }

      // ✅ إظهار رسالة نجاح
      toast.success("✅ تم تحديث بيانات المنتج بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: {
          background:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });

      // 🔄 تحديث البيانات بعد الحفظ
      fetchProduct();
    } catch (error) {
      console.error("❌ خطأ في تحديث بيانات المنتج:", error);
      alert("❌ حدث خطأ أثناء تحديث المنتج!");
    }

    setLoading(false);
  };
  const handleUpdateMainImage = async () => {
    if (!mainImage) {
      alert("Please select a new main image.");
      return;
    }

    try {
      const mainImageRef = ref(
        storage,
        `product-images/${id}/main-${mainImage.name}`
      );
      await uploadBytes(mainImageRef, mainImage);
      const mainImageUrl = await getDownloadURL(mainImageRef);

      const productRef = doc(db, "product", id!);
      await updateDoc(productRef, {
        imageUrl: mainImageUrl,
      });

      // ✅ إظهار رسالة نجاح
      toast.success("✅ تم تحديث صورة المنتج بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: {
          background:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });
      fetchProduct();
    } catch (error) {
      console.error("Error updating main image:", error);
    }
  };

  if (!product) {
    return <p>Loading...</p>;
  }

  const handleAddExtraImages = async () => {
    if (extraImages.length === 0) return;

    try {
      for (const image of extraImages) {
        const imageRef = ref(storage, `product-images/${id}/${image.name}`);
        await uploadBytes(imageRef, image);
        const imageUrl = await getDownloadURL(imageRef);

        await addDoc(collection(db, "product", id!, "productImages"), {
          imageUrl,
        });
      }

      toast.success("✅ تم تحديث بيانات الصور الاضافية بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: {
          background:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });
      setExtraImages([]);
      fetchProduct(); // إعادة تحميل الصور الإضافية
    } catch (error) {
      console.error("❌ خطأ في إضافة الصور الإضافية:", error);
    }
  };
  // ❌ دالة حذف الرقم التسلسلي

  // ✅ جلب البيانات عند تحميل الصفحة

  const confirmDeleteAllSerials = async () => {
    if (!id) {
      alert("⚠️ لا يوجد معرف للمنتج!");
      return;
    }

    try {
      const serialNumberCollection = collection(db, "SerailNumber");
      const serialQuery = query(
        serialNumberCollection,
        where("productId", "==", id)
      );

      let serialSnapshot = await getDocs(serialQuery);
      let batchCount = 0;

      while (!serialSnapshot.empty) {
        const batch = writeBatch(db);

        serialSnapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();
        batchCount++;

        // 🔄 جلب المزيد من الوثائق بعد الحذف
        serialSnapshot = await getDocs(serialQuery);

        console.log(`🗑️ تم حذف دفعة رقم ${batchCount}`);
      }
      // ✅ تفريغ حالة الأرقام التسلسلية بعد الحذف مباشرة
      setSerialNumbers([]);
      toast.success("✅ تم حذف جميع الأرقام التسلسلية بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: {
          background:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });

      setIsDeleteModalOpen(false);
      fetchSerialNumbers(); // 🔄 تحديث القائمة بعد الحذف
    } catch (error) {
      console.error("❌ خطأ في حذف الأرقام التسلسلية:", error);
      alert("❌ حدث خطأ أثناء حذف الأرقام التسلسلية!");
    }
  };

  const DeleteConfirmationModal: React.FC<DeleteModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
  }) => {
    const [inputValue, setInputValue] = useState(""); // ✅ حالة لتتبع الإدخال

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
          <h2 className="text-xl font-bold mb-4 text-center font-custom">
            تأكيد الحذف
          </h2>
          <p className="text-gray-700 text-center mb-4 font-custom">
            الرجاء كتابة في الحقل أدناه لتأكيد الحذف.
          </p>

          {/* ✅ حقل الإدخال */}
          <input
            type="text"
            placeholder="كود الحذف"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-red-500 font-custom mb-4 text-center"
          />

          <div className="flex justify-center gap-4">
            {/* ✅ زر الحذف مع شرط التفعيل */}
            <button
              onClick={() => onConfirm()}
              disabled={inputValue !== "SerailNumber"} // ✅ التحقق من الإدخال
              className={`px-4 py-2 rounded-lg shadow-md transition font-custom ${
                inputValue === "SerailNumber"
                  ? "bg-red-600 text-white hover:bg-red-700"
                  : "bg-gray-300 text-gray-600 cursor-not-allowed"
              }`}
            >
              نعم، حذف
            </button>

            <button
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-gray-600 transition font-custom"
            >
              إلغاء
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteClick = (serialId: string) => {
    setSerialToDelete(serialId); // حفظ الرقم التسلسلي في المتغير
    setisdeleteSerailModalOpen(true); // فتح المودال
  };
  const confirmDeleteSerial = async () => {
    if (!serialToDelete) return;

    try {
      await deleteDoc(doc(db, "SerailNumber", serialToDelete));
      toast.success("تم حذف السيريال بنجاح", {
        duration: 3000,
        position: "top-right",
        style: {
          background:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });
      setisdeleteSerailModalOpen(false);
      fetchSerialNumbers(); // تحديث القائمة بعد الحذف
    } catch (error) {
      console.error("❌ خطأ في حذف الرقم التسلسلي:", error);
    }
  };

  // ✅ دالة فتح المودال وتحديد الصورة التي سيتم حذفها
  const handleDeleteImageClick = (
    image: string | File,
    isFirestore: boolean
  ) => {
    setImageToDelete(image);
    setIsFromFirestore(isFirestore);
    setisDeleteImageModalOpen(true);
  };

  // ✅ دالة تأكيد الحذف
  const confirmDeleteImage = async () => {
    if (!imageToDelete) return;

    if (isFromFirestore && typeof imageToDelete === "string") {
      try {
        // 🔹 جلب جميع الوثائق في الكولكشن الفرعي `product/{id}/productImages`
        const imagesCollection = collection(
          db,
          "product",
          id!,
          "productImages"
        );
        const imagesSnapshot = await getDocs(imagesCollection);

        let imageDocId = null;

        // 🔎 البحث عن الوثيقة التي تحتوي على `imageUrl` الذي يطابق `imageToDelete`
        imagesSnapshot.forEach((doc) => {
          if (doc.data().imageUrl === imageToDelete) {
            imageDocId = doc.id;
          }
        });

        // ✅ التحقق مما إذا تم العثور على الوثيقة
        if (imageDocId) {
          await deleteDoc(doc(db, "product", id!, "productImages", imageDocId));
          console.log(`✅ تم حذف الصورة من Firestore: ${imageDocId}`);

          // 🔄 تحديث القائمة بعد الحذف
          setStoredExtraImages((prevImages) =>
            prevImages.filter((url) => url !== imageToDelete)
          );
        } else {
          console.error("❌ لم يتم العثور على الوثيقة التي تحتوي على الصورة.");
        }
      } catch (error) {
        console.error("❌ خطأ في حذف الصورة من Firestore:", error);
      }
    } else if (!isFromFirestore && imageToDelete instanceof File) {
      setExtraImages((prevImages) =>
        prevImages.filter((file) => file !== imageToDelete)
      );
    }

    setisDeleteImageModalOpen(false);
    setImageToDelete(null);
  };

  return (
    <div className=" w-full mx-auto bg-white rounded-xl shadow-lg  mb-20">
      <div className="max-w-full flex items-center justify-between bg-gradient-to-r from-[#197184] to-[#269BB4] text-white px-6 py-3 rounded-lg shadow-md">
        {/* أيقونة الرجوع للخلف */}

        {/* العنوان */}
        <h1
          className="text-xl font-bold flex-1"
          style={{ fontFamily: "Tajawal, sans-serif" }}
        >
          تعديل بيانات منتج
        </h1>

        <FontAwesomeIcon
          icon={faArrowLeft}
          size="lg"
          className="cursor-pointer hover:opacity-80 transition  mr-4"
          onClick={() => navigate(-1)}
        />
        {/* أيقونة إعادة تعيين الحقول */}
      </div>

      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4 p-4 ">
        {/* الحقول الأساسية */}
        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            كود المنتج:
          </label>
          <input
            type="text"
            value={product.code}
            onChange={(e) => setProduct({ ...product, code: e.target.value })}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>
        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            اسم المنتج:
          </label>
          <input
            type="text"
            value={product.name}
            onChange={(e) => setProduct({ ...product, name: e.target.value })}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>
        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            نقاط المنتج:
          </label>
          <input
            type="text"
            value={product.points}
            onChange={(e) => setProduct({ ...product, points: e.target.value })}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>

        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            موقع خزن المنتج:
          </label>
          <input
            type="text"
            value={product.location}
            onChange={(e) =>
              setProduct({ ...product, location: e.target.value })
            }
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>

        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            السعر:
          </label>
          <input
            type="text"
            value={product.price}
            onChange={(e) => setProduct({ ...product, price: e.target.value })}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>

        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            التقييم:
          </label>
          <input
            type="text"
            value={product.rating}
            onChange={(e) => setProduct({ ...product, rating: e.target.value })}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>
        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            الكمية:
          </label>
          <input
            type="text"
            value={product.Quantity}
            onChange={(e) =>
              setProduct({ ...product, Quantity: e.target.value })
            }
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>

        <div className="col-span-2">
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            الوصف:
          </label>
          <textarea
            value={product.productDescription}
            onChange={(e) =>
              setProduct({ ...product, productDescription: e.target.value })
            }
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          ></textarea>
        </div>

        {/* قائمة التاكات */}
        <div className="col-span-2">
          <h3 className="text-lg font-semibold mb-2 font-custom">
            اختر التاكات:
          </h3>
          <div className="h-40 overflow-y-auto border rounded-lg p-2 ">
            {tags.map((tag) => (
              <div
                key={tag.id}
                className={`flex items-center space-x-3 p-2 border-b cursor-pointer hover:bg-gray-100 rounded-lg 
          ${
            selectedTags.includes(tag.id)
              ? "bg-white text-cyan-700  border-gray-300" // ✅ لون مختلف للتاكات المحددة
              : "bg-white text-gray-700 border-gray-300"
          }`}
                onClick={() => toggleTagSelection(tag.id)}
              >
                <img
                  src={tag.imageUrl}
                  alt={tag.name}
                  className="w-12 h-12 rounded-full object-cover"
                />
                <span className="font-semibold font-custom">{tag.name}</span>
              </div>
            ))}
          </div>
        </div>
        {/* ✅ عرض الصورة الرئيسية */}

        <div className="col-span-2 flex justify-end pl-0 w-full">
          <button
            type="submit"
            disabled={loading}
            className="w-1/4 p-3  bg-cyan-700  text-white font-semibold rounded-lg hover:bg-cyan-600 transition font-custom"
          >
            {loading ? "جاري الإضافة..." : "تحديث البيانات الاساسية للمنتج"}
          </button>
        </div>
      </form>
      <div className="h-[2px] bg-gradient-to-r from-gray-300 via-gray-400 to-gray-300 my-4"></div>
      {/* 
      <div className="col-span-2 m-4">
        <label className="block text-gray-700 font-semibold mb-2 text-lg font-custom">
          الصورة الرئيسية:
        </label>
        <div className="flex items-center space-x-6 p-4 rounded-lg border">
          {mainImageUrl && (
            <img
              src={mainImageUrl}
              alt="صورة المنتج"
              className="w-24 h-24 object-cover rounded-lg border-2 border-gray-300 shadow-lg"
            />
          )}
          <label className="cursor-pointer bg-cyan-600 text-white px-6 py-3 text-lg rounded-lg shadow hover:bg-cyan-700 transition font-custom">
            تغيير الصورة
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setMainImage(e.target.files?.[0] || null)}
              className="hidden"
            />
          </label>
          {mainImage && (
            <button
              onClick={handleUpdateMainImage}
              className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition"
            >
              تحديث
            </button>
          )}
        </div>
      </div> */}

      <div className="col-span-2 m-4 flex justify-center items-center p-4">
        <label className="text-black font-semibold text-lg font-custom text-center">
          الصور الخاصة بالمنتج
        </label>
      </div>

      <div className="col-span-2 m-4">
        <label className="block text-gray-700 font-semibold mb-2 text-lg font-custom">
          الصورة الرئيسية:
        </label>
        <div className="flex items-center space-x-6 p-4 rounded-lg border">
          {/* ✅ عرض الصورة */}
          {mainImageUrl && (
            <img
              src={mainImageUrl}
              alt="صورة المنتج"
              className="w-24 h-24  m-4 object-cover rounded-lg border-2 border-gray-300 shadow-lg"
            />
          )}

          {/* ✅ زر اختيار الصورة */}
          <label className="cursor-pointer flex items-center justify-center w-1/4 bg-cyan-600 text-white px-6 py-3 text-lg rounded-lg shadow hover:bg-cyan-700 transition font-custom text-center">
            اختيار صورة رئيسية جديدة
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  setMainImage(file); // حفظ الصورة المختارة
                  setMainImageUrl(URL.createObjectURL(file)); // تحديث الصورة فورًا
                }
              }}
              className="hidden"
            />
          </label>

          {/* ✅ زر تحديث الصورة */}
          {mainImage && (
            <button
              onClick={handleUpdateMainImage}
              className="bg-green-600 w-1/4 text-white px-6 py-3 text-lg rounded-lg  font-custom hover:bg-green-700 transition"
            >
              تحديث
            </button>
          )}
        </div>
      </div>

      {/* ✅ عرض الصور الإضافية */}

      {/* ✅ عرض الصور الإضافية */}
      <div className="col-span-2 m-4">
        <label className="block text-gray-700 font-semibold mb-2 text-lg font-custom">
          الصور الإضافية:
        </label>
        {/* ✅ عرض الصور (القديمة والجديدة معًا) */}
        <div className="flex flex-wrap gap-3 p-3 bg-gray-100 rounded-lg shadow">
          {/* ✅ عرض الصور المخزنة في Firestore */}
          {storedExtraImages.map((url, index) => (
            <img
              key={`stored-${index}`}
              src={url}
              alt={`صورة ${index + 1}`}
              className="w-20 h-20 object-cover rounded-lg border border-gray-300 shadow-lg cursor-pointer"
              onClick={() => handleDeleteImageClick(url, true)}
            />
          ))}

          {/* ✅ عرض الصور الجديدة التي تم اختيارها من الجهاز */}
          {extraImages.map((file, index) => (
            <img
              key={`new-${index}`}
              src={URL.createObjectURL(file)}
              alt={`صورة جديدة ${index + 1}`}
              className="w-20 h-20 object-cover rounded-lg border-2 border-blue-500 shadow-lg cursor-pointer"
              onClick={() => handleDeleteImageClick(file, false)}
            />
          ))}

          {/* ✅ عرض الصور الجديدة التي تم اختيارها من الجهاز */}
        </div>
        <div className="mt-2 flex items-center gap-3">
          {/* ✅ زر اختيار صور جديدة */}
          <label className="mt-2 flex items-center justify-center cursor-pointer bg-cyan-600 text-white px-6 py-3 text-lg rounded-lg shadow hover:bg-cyan-700 transition font-custom w-full">
            <span>إضافة صور جديدة</span>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={(e) =>
                setExtraImages([
                  ...extraImages,
                  ...Array.from(e.target.files || []),
                ])
              }
              className="hidden"
            />
          </label>

          {/* ✅ زر رفع الصور */}
          {extraImages.length > 0 && (
            <button
              onClick={handleAddExtraImages}
              className="mt-2 flex items-center justify-center cursor-pointer bg-green-600 text-white px-6 py-3 text-lg rounded-lg shadow hover:bg-cyan-700 transition font-custom w-full"
            >
              رفع الصور
            </button>
          )}
        </div>
      </div>

      {/* ✅ مودال تأكيد الحذف */}
      {isDeleteImageModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-bold mb-4">تأكيد حذف الصورة</h2>
            <p className="text-gray-700 mb-4">
              هل أنت متأكد أنك تريد حذف هذه الصورة؟
            </p>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setisDeleteImageModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition"
              >
                إلغاء
              </button>
              <button
                onClick={confirmDeleteImage}
                className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition"
              >
                حذف
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="h-[2px] bg-gradient-to-r from-gray-300 via-gray-400 to-gray-300 my-4"></div>

      {/* 🛠 قائمة الأرقام التسلسلية */}
      <div className="col-span-2  gap-4 p-4">
        <h3 className="text-lg font-semibold mb-2 font-custom">
          الأرقام التسلسلية:
        </h3>

        {/* 🔎 حقل البحث وزر فتح المودال */}
        <div className="flex items-center gap-3 mb-3">
          <div className="relative flex-grow">
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
            />
            <input
              type="text"
              placeholder="بحث عن الرقم التسلسلي..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-3 pl-10 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
            />
          </div>

          {/* زر فتح المودال */}
          <button
            onClick={() => setIsModalOpen(true)}
            className="mt-2 flex items-center justify-center cursor-pointer bg-cyan-600 text-white px-6 py-3 text-lg rounded-lg shadow hover:bg-cyan-700 transition font-custom  w-1/5"
          >
            <FontAwesomeIcon className="px-2" icon={faPlus} /> إضافة
          </button>
          <button
            onClick={handleClick}
            className="mt-2 flex items-center justify-center cursor-pointer bg-red-600 text-white px-6 py-3 text-lg rounded-lg shadow hover:bg-red-700 transition font-custom  w-1/5"
          >
            <FontAwesomeIcon className="px-2" icon={faTrash} /> حذف الجميع
          </button>

          {/* مودال تأكيد الحذف */}
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onConfirm={confirmDeleteAllSerials}
          />
        </div>
      </div>
      {filteredSerialNumbers.length > 0 ? (
        <div className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4 z-10">
          {filteredSerialNumbers.map((serial) => (
            <div
              key={serial.id}
              className="relative bg-white p-4 rounded-lg shadow-md border border-gray-300 flex flex-col items-center justify-center transition hover:shadow-lg z-10"
            >
              {/* ✅ الرقم التسلسلي */}
              <span className="text-gray-700 font-semibold text-lg">
                {serial.id}
              </span>

              {/* ✅ زر الحذف - يظهر عند تمرير الماوس */}
              <button
                type="button"
                onClick={() => handleDeleteClick(serial.id)}
                className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-full shadow-md opacity-0   hover:bg-red-700 transition group-hover:opacity-100"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center py-10">
          {/* ✅ أيقونة مجلد فارغ */}
          <FontAwesomeIcon
            icon={faFolderOpen}
            className="text-gray-400 text-6xl mb-4"
          />
          {/* ✅ النص التوضيحي */}
          <p className="text-gray-500 text-lg font-custom">
            لا توجد أرقام تسلسلية لهذا المنتج.
          </p>
        </div>
      )}

      {/* 🔹 زر تحميل المزيد */}
      {lastVisible !== null && (
        <button
          onClick={() => fetchSerialNumbers(lastVisible)}
          className="mt-3 px-4 py-2  bg-cyan-600 text-white rounded-lg hover:bg-cyan-700 transition font-custom "
        >
          تحميل المزيد
        </button>
      )}

      {/* 📌 مودال إضافة رقم تسلسلي */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[9999]">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-bold mb-4 text-center font-custom">
              إضافة أرقام تسلسلية
            </h2>

            {/* ✅ زر اختيار ملف Excel */}
            <label className="cursor-pointer bg-gray-200 text-gray-700 px-4 py-2 rounded-lg shadow hover:bg-gray-300 transition font-custom w-full flex justify-center items-center">
              📂 اختر ملف Excel
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload} // ✅ ربط الدالة هنا
                className="hidden"
              />
            </label>

            {/* ✅ عرض اسم الملف */}
            {excelFile && (
              <p className="text-sm text-gray-600 text-center mt-2">
                الملف المختار: {excelFile.name}
              </p>
            )}

            <div className="flex justify-end gap-3 mt-4">
              {/* زر إلغاء */}
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition font-custom"
              >
                إلغاء
              </button>

              {/* زر رفع البيانات */}
              <button
                onClick={handleUploadSerials}
                className={`px-4 py-2 rounded-lg transition font-custom ${
                  excelFile
                    ? "bg-green-600 text-white hover:bg-green-700"
                    : "bg-gray-300 text-gray-600 cursor-not-allowed"
                }`}
                disabled={!excelFile || isUploading}
              >
                {isUploading ? "جارِ التحميل..." : "رفع"}
              </button>
            </div>
          </div>
        </div>
      )}

      {isdeleteSerailModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative z-[10000]">
            <h2 className="text-lg font-bold mb-4 font-custom text-center">
              حذف رقم تسلسلي
            </h2>

            <div className="flex justify-end gap-3">
              <button
                onClick={() => setisdeleteSerailModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition font-custom"
              >
                إلغاء
              </button>
              <button
                onClick={confirmDeleteSerial}
                className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition font-custom"
              >
                حذف
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProduct;
