import React, { useState } from "react";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-hot-toast";

const AddTag: React.FC = () => {
  const [name, setName] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);

  const storage = getStorage();

  // ✅ **معاينة الصورة قبل الرفع**
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  // ✅ **رفع البيانات إلى Firestore**
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name || !image) {
      toast.error("يرجى إدخال اسم العلامة واختيار صورة.", {
        duration: 3000,
        position: "top-right",
        style: { background: "#dc3545", color: "#fff" },
      });
      return;
    }

    setLoading(true);
    try {
      // ✅ **رفع الصورة إلى Firestore Storage**
      const imageRef = ref(storage, `tagsImage/${image.name}`);
      await uploadBytes(imageRef, image);
      const ImageUrl = await getDownloadURL(imageRef);

      // ✅ **إضافة البيانات إلى Firestore Collection**
      await addDoc(collection(db, "tags"), {
        name,
        ImageUrl,
      });

      toast.success("تمت إضافة العلامة بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: { background: "#28a745", color: "#fff" },
      });

      // ✅ **إعادة ضبط القيم**
      setName("");
      setImage(null);
      setPreview(null);
    } catch (error) {
      console.error("خطأ في إضافة العلامة:", error);
      toast.error("فشل في إضافة العلامة، يرجى المحاولة مرة أخرى.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto my-10 bg-white p-8 rounded-xl shadow-lg">
      <h1
        className="text-2xl font-bold text-gray-700 text-center mb-6 font-tajawal"
        style={{ fontFamily: "Tajawal, sans-serif" }}
      >
        إضافة علامة جديدة
      </h1>

      <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
        {/* ✅ **اسم العلامة** */}
        <div>
          <label
            className="block text-gray-600 font-semibold mb-1"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          >
            اسم العلامة:
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          />
        </div>

        {/* ✅ **رفع الصورة** */}
        <div>
          <label
            className="block text-gray-600 font-semibold mb-2 font-tajawal"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          >
            الصورة:
          </label>

          <div className="flex items-center space-x-4">
            <label
              className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition"
              style={{ fontFamily: "Tajawal, sans-serif" }}
            >
              اختر صورة
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
            </label>

            {preview && (
              <img
                src={preview}
                alt="معاينة الصورة"
                className="w-20 h-20 object-cover rounded-lg border shadow"
              />
            )}
          </div>
        </div>

        {/* ✅ **زر الإضافة** */}
        <div>
          <button
            type="submit"
            disabled={loading}
            className="w-full p-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          >
            {loading ? "جاري الإضافة..." : "إضافة العلامة"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTag;
