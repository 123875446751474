import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { Modal, Box, Typography, Button } from "@mui/material";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSearch, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface Facet {
  id: string;
  name: string;
}

const FacetsList: React.FC = () => {
  const [facets, setFacets] = useState<Facet[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedFacetId, setSelectedFacetId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();

  // ✅ **جلب البيانات من Firestore**
  const fetchFacets = async () => {
    setLoading(true);
    try {
      const facetsCollection = collection(db, "facets");
      const facetsDocs = await getDocs(facetsCollection);

      const fetchedFacets: Facet[] = [];
      facetsDocs.forEach((doc) => {
        fetchedFacets.push({
          id: doc.id,
          name: doc.data().name || "بدون اسم",
        });
      });

      console.log("✅ Processed Facets:", fetchedFacets);
      setFacets(fetchedFacets);
      setLoading(false);
    } catch (error) {
      console.error("❌ Error fetching facets:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFacets();
  }, []);

  // ✅ **فتح المودال عند الضغط على زر الحذف**
  const handleOpenModal = (id: string) => {
    setSelectedFacetId(id);
    setOpenModal(true);
  };

  // ✅ **إغلاق المودال**
  const handleCloseModal = () => {
    setSelectedFacetId(null);
    setOpenModal(false);
  };

  // ✅ **حذف العنصر من Firestore**
  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, "facets", id));
      toast.success("تم حذف العنصر بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: {
          backgroundImage:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });

      fetchFacets();
      handleCloseModal();
    } catch (error) {
      console.error("Error deleting facet:", error);
    }
  };

  // ✅ **فلترة البيانات بناءً على البحث**
  const filteredFacets = facets.filter(
    (facet) =>
      facet.name && facet.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="product-container">
      {/* ✅ **عنوان الصفحة** */}
      <div className="header-container">
        <div className="search-wrapper">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input
            type="text"
            placeholder="ابحث عن الفئات..."
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <button
          className="add-button"
          onClick={() => navigate("/dashboard/add-facet")}
        >
          إضافة فئة جديدة
        </button>
      </div>

      {/* ✅ **عرض البيانات داخل جدول** */}
      {loading ? (
        <p className="text-center">جاري تحميل البيانات...</p>
      ) : (
        <div className="table-container">
          <table className="product-table">
            <thead className="color-table">
              <tr>
                <th>#</th>
                <th>اسم الفئة</th>
                <th>الإجراء</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredFacets.map((facet, index) => (
                <tr key={facet.id}>
                  <td>{index + 1}</td>
                  <td>{facet.name}</td>
                  <td>
                    <div className="icon-container">
                      <button
                        className="icon-button"
                        onClick={() =>
                          navigate(`/dashboard/edit-facet/${facet.id}`)
                        }
                      >
                        <FontAwesomeIcon icon={faEdit} title="تعديل الفئة" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="icon-container">
                      <button
                        className="icon-button"
                        onClick={() => handleOpenModal(facet.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} title="حذف الفئة" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ✅ **مودال تأكيد الحذف** */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            هل أنت متأكد من أنك تريد حذف هذه الفئة؟
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => selectedFacetId && handleDelete(selectedFacetId)}
            >
              نعم، احذف الفئة
            </Button>
            <Button variant="outlined" onClick={handleCloseModal}>
              إلغاء
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default FacetsList;
