import React, { useState, useEffect, use } from "react";
import { db } from "../firebase";
import { collection, addDoc, getDocs, doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface Tag {
  id: string;
  name: string;
  imageUrl: string;
}

const AddProduct: React.FC = () => {
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [rating, setRating] = useState("");
  const [productDescription, setproductDescription] = useState("");
  const [location, setLocation] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [points, setpoints] = useState("");
  const [Quantity, setQuantity] = useState("");
  // ✅ الصور الإضافية
  const [extraImages, setExtraImages] = useState<File[]>([]);
  const [extraPreviews, setExtraPreviews] = useState<string[]>([]);

  // 🔹 التاكات (Tags)
  const [tags, setTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    const fetchTags = async () => {
      const tagsCollection = collection(db, "tags");
      const tagsSnapshot = await getDocs(tagsCollection);
      const fetchedTags = tagsSnapshot.docs.map((doc) => ({
        id: doc.id, // ✅ تعيين ID بشكل منفصل
        name: doc.data().name, // ✅ تجنب الانتشار العشوائي
        imageUrl: doc.data().ImageUrl,
      }));
      setTags(fetchedTags);
    };

    fetchTags();
  }, []);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  // ✅ دالة حذف الصورة من القائمة
  const removeImage = (index: number) => {
    setExtraPreviews((prev) => prev.filter((_, i) => i !== index));
  };
  // 🔹 تحديد التاكات
  const toggleTagSelection = (tagId: string) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tagId)
        ? prevTags.filter((id) => id !== tagId)
        : [...prevTags, tagId]
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      code.trim() === "" ||
      name.trim() === "" ||
      price.trim() === "" ||
      rating.trim() === "" ||
      productDescription.trim() === "" ||
      location.trim() === "" ||
      points.trim() === "" ||
      Quantity.trim() === "" ||
      !image ||
      selectedTags.length === 0
    ) {
      toast.error(
        "❌ يرجى ملء جميع الحقول المطلوبة، بما في ذلك اختيار التاكات!",
        {
          duration: 3000,
          position: "top-right",
          style: {
            background:
              "linear-gradient(to top, rgb(10, 113, 132), rgb(38, 55, 180))",
            color: "#fff",
            fontSize: "16px",
          },
        }
      );
      return;
    }

    setLoading(true);
    try {
      const storage = getStorage();

      // ✅ رفع الصورة الأساسية
      const imageRef = ref(storage, `product-images/${image.name}`);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      // ✅ إضافة المنتج إلى كولكشن `product`
      const productRef = await addDoc(collection(db, "product"), {
        code,
        name,
        price,
        rating,
        productDescription: productDescription,
        location,
        points: Number(points),
        Quantity,
        imageUrl,
      });

      // ✅ إضافة التاكات المحددة إلى المنتج داخل كولكشن `tags`
      for (const tagId of selectedTags) {
        await setDoc(doc(db, "product", productRef.id, "tags", tagId), {}); // إضافة مستند فارغ
      }

      // ✅ رفع الصور الإضافية إلى `productImages`
      for (const extraImage of extraImages) {
        const imageId = doc(
          collection(db, "product", productRef.id, "productImages")
        ).id;
        const extraImageRef = ref(
          storage,
          `product-images/${productRef.id}/${imageId}.jpg`
        );
        await uploadBytes(extraImageRef, extraImage);
        const extraImageUrl = await getDownloadURL(extraImageRef);

        await setDoc(
          doc(db, "product", productRef.id, "productImages", imageId),
          {
            imageUrl: extraImageUrl,
          }
        );
      }

      toast.success("✅ تم إضافة المنتج والصور بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: {
          background:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });

      // ✅ إعادة تعيين القيم بعد الإضافة
      setCode("");
      setName("");
      setPrice("");
      setRating("");
      setproductDescription("");
      setLocation("");
      setpoints("");
      setQuantity("");
      setImage(null);
      setPreview(null);
      setExtraImages([]);
      setExtraPreviews([]);
      setSelectedTags([]);
    } catch (error) {
      console.error("❌ خطأ في إضافة المنتج:", error);
      toast.error("⚠️ فشل في إضافة المنتج، يرجى المحاولة مرة أخرى.");
    } finally {
      setLoading(false);
    }
  };

  const resetFields = () => {
    setCode("");
    setName("");
    setPrice("");
    setRating("");
    setproductDescription("");
    setLocation("");
    setpoints("");
    setQuantity("");
    setImage(null);
    setPreview(null);
    setExtraImages([]);
    setExtraPreviews([]);
    setSelectedTags([]);
    toast.success("تم مسح جميع الحقول بنجاح!", {
      duration: 2000,
      position: "top-right",
    });
  };
  // ✅ اختيار صور إضافية
  const handleExtraImagesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      setExtraImages(files);

      // عرض المعاينة للصور المختارة
      const previews = files.map((file) => URL.createObjectURL(file));
      setExtraPreviews(previews);
    }
  };
  return (
    <div className="w-full mx-auto bg-white rounded-xl shadow-lg ">
      <div className="max-w-full flex items-center justify-between bg-gradient-to-r from-[#197184] to-[#269BB4] text-white px-6 py-3 rounded-lg shadow-md">
        {/* أيقونة الرجوع للخلف */}

        {/* العنوان */}
        <h1
          className="text-xl font-bold flex-1"
          style={{ fontFamily: "Tajawal, sans-serif" }}
        >
          إضافة منتج جديد
        </h1>

        <FontAwesomeIcon
          icon={faTrash}
          size="lg"
          className="cursor-pointer hover:opacity-80 transition ml-4"
          onClick={resetFields}
        />
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="lg"
          className="cursor-pointer hover:opacity-80 transition  mr-4"
          onClick={() => navigate(-1)}
        />
        {/* أيقونة إعادة تعيين الحقول */}
      </div>
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4 p-4 ">
        {/* الحقول الأساسية */}
        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            كود المنتج:
          </label>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>

        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            اسم المنتج:
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>

        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            نقاط المنتج:
          </label>
          <input
            type="text"
            value={points}
            onChange={(e) => setpoints(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>

        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            موقع خزن المنتج:
          </label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>
        {/* السعر والتقييم */}
        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            السعر:
          </label>
          <input
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>
        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            التقييم:
          </label>
          <input
            type="text"
            value={rating}
            onChange={(e) => setRating(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>
        <div>
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            الكمية:
          </label>
          <input
            type="text"
            value={Quantity}
            onChange={(e) => setQuantity(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          />
        </div>
        {/* الوصف */}
        <div className="col-span-2">
          <label className="block text-gray-600 font-semibold mb-1 font-custom">
            الوصف:
          </label>
          <textarea
            value={productDescription}
            onChange={(e) => setproductDescription(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 font-custom"
          ></textarea>
        </div>

        {/* تحميل الصورة */}
        <div className="col-span-2">
          {/* العنوان - توحيد النمط مع باقي العناوين */}
          <label className="block text-gray-700 font-semibold mb-2 text-lg font-custom">
            الصورة الرئيسية:
          </label>

          <div className="w-full flex items-center space-x-6 p-4 rounded-lg border">
            {/* زر اختيار الصورة مع تكبير حجمه */}
            <label className="cursor-pointer bg-cyan-600 text-white px-6 py-3 text-lg rounded-lg shadow hover:bg-cyan-700 transition font-custom">
              اختر صورة
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
            </label>

            {/* عرض الصورة المختارة مع اسم الصورة، وإضافة مسافات مناسبة */}
            {preview && (
              <div className="flex items-center space-x-6">
                <label className="text-black font-mono text-lg font-custom">
                  الصورة المختارة:
                </label>
                <img
                  src={preview}
                  alt="معاينة الصورة"
                  className="w-24 h-24 object-cover rounded-lg border-2 border-gray-300 shadow-lg"
                />
              </div>
            )}
          </div>
        </div>

        {/* قائمة التاكات */}
        <div className="col-span-2">
          <h3 className="text-lg font-semibold mb-2 font-custom">
            اختر التاكات:
          </h3>
          <div className="h-40 overflow-y-auto border rounded-lg p-2 ">
            {tags.map((tag) => (
              <div
                key={tag.id}
                className="flex items-center space-x-3 p-2 border-b cursor-pointer hover:bg-gray-100"
                onClick={() => toggleTagSelection(tag.id)}
              >
                <img
                  src={tag.imageUrl}
                  alt={tag.name}
                  className="w-12 h-12 rounded-full object-cover"
                />
                <span
                  className={`font-semibold font-custom ${
                    selectedTags.includes(tag.id)
                      ? "text-cyan-700"
                      : "text-gray-700"
                  }`}
                >
                  {tag.name}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* اختيار الصور الإضافية */}
        <div className="col-span-2">
          {/* عنوان القسم */}
          <label className="block text-gray-700 font-semibold mb-2 text-lg font-custom">
            صور إضافية:
          </label>

          {/* زر اختيار الصور */}
          <label className="flex items-center justify-center cursor-pointer bg-cyan-600 text-white px-6 py-3 text-lg rounded-lg shadow hover:bg-blue-600 transition font-custom w-full">
            <span>اختر صور</span>
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleExtraImagesChange}
              className="hidden"
            />
          </label>

          {/* عرض الصور الإضافية مع زر الحذف */}
          {extraPreviews.length > 0 && (
            <div className="flex flex-wrap gap-3 mt-4 p-3 bg-gray-100 rounded-lg shadow">
              {extraPreviews.map((preview, index) => (
                <div key={index} className="relative group">
                  {/* عرض الصورة */}
                  <img
                    src={preview}
                    alt={`صورة إضافية ${index + 1}`}
                    className="w-24 h-24 object-cover rounded-lg border shadow-lg"
                  />

                  {/* زر حذف الصورة - يظهر عند تمرير الفأرة */}
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-2 shadow-md transition-opacity duration-300 opacity-0 group-hover:opacity-100 hover:bg-red-700"
                  >
                    <FontAwesomeIcon icon={faTrash} size="sm" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* زر الإضافة */}
        <div className="col-span-2 flex justify-end pl-0 w-full">
          <button
            type="submit"
            disabled={loading}
            className="w-44 p-3  bg-cyan-700  text-white font-semibold rounded-lg hover:bg-cyan-600 transition font-custom"
          >
            {loading ? "جاري الإضافة..." : "إضافة المنتج"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;
