import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const EditOffer: React.FC = () => {
  const { id } = useParams(); // ✅ الحصول على معرف الإعلان من الرابط
  const navigate = useNavigate();

  const [offerName, setOfferName] = useState("");
  const [offerType, setOfferType] = useState("");
  const [offerDescription, setOfferDescription] = useState("");
  const [dateUpload, setDateUpload] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const storage = getStorage();

  // ✅ **تحميل بيانات الإعلان الحالي**
  useEffect(() => {
    const fetchOfferData = async () => {
      if (!id) return;

      const offerRef = doc(db, "offers", id);
      const offerSnap = await getDoc(offerRef);

      if (offerSnap.exists()) {
        const offerData = offerSnap.data();
        setOfferName(offerData.offername || "");
        setOfferType(offerData.offertype || "");
        setOfferDescription(offerData.overdescription || "");
        setDateUpload(
          offerData.dateupload?.seconds
            ? new Date(offerData.dateupload.seconds * 1000)
                .toISOString()
                .split("T")[0]
            : ""
        );
        setPreview(offerData.ImageId || "");
      } else {
        toast.error("الإعلان غير موجود!", {
          position: "top-right",
        });
        navigate("/dashboard/offers");
      }
    };

    fetchOfferData();
  }, [id, navigate]);

  // ✅ **تغيير الصورة ومعاينتها**
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => setPreview(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  // ✅ **حفظ التعديلات**
  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!offerName || !offerType || !offerDescription || !dateUpload) {
      toast.error("يرجى ملء جميع الحقول!", {
        duration: 3000,
        position: "top-right",
      });
      return;
    }

    setLoading(true);
    try {
      let imageUrl = preview; // احتفظ بنفس رابط الصورة إذا لم يتم اختيار صورة جديدة

      if (image) {
        // **رفع الصورة الجديدة إلى Storage**
        const imageRef = ref(storage, `/Images/offers/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      // **تحديث بيانات الإعلان**
      const offerRef = doc(db, "offers", id!);
      await updateDoc(offerRef, {
        offername: offerName,
        offertype: offerType,
        overdescription: offerDescription,
        dateupload: new Date(dateUpload),
        ImageId: imageUrl,
      });

      toast.success("تم تحديث الإعلان بنجاح!", {
        duration: 3000,
        position: "top-right",
      });

      navigate("/dashboard/offers");
    } catch (error) {
      console.error("❌ خطأ أثناء التحديث:", error);
      toast.error("حدث خطأ أثناء تحديث الإعلان!", {
        duration: 3000,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto my-10 bg-white p-8 rounded-xl shadow-lg">
      <h1 className="text-2xl font-bold text-gray-700 text-center mb-6 font-tajawal">
        تعديل الإعلان
      </h1>
      <form onSubmit={handleUpdate} className="grid grid-cols-2 gap-4">
        {/* اسم الإعلان */}
        <div>
          <label className="block text-gray-600 font-semibold mb-1">
            اسم الإعلان:
          </label>
          <input
            type="text"
            value={offerName}
            onChange={(e) => setOfferName(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* نوع الإعلان */}
        <div>
          <label className="block text-gray-600 font-semibold mb-1">
            نوع الإعلان:
          </label>
          <input
            type="text"
            value={offerType}
            onChange={(e) => setOfferType(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* تاريخ الإضافة */}
        <div>
          <label className="block text-gray-600 font-semibold mb-1">
            تاريخ الإضافة:
          </label>
          <input
            type="date"
            value={dateUpload}
            onChange={(e) => setDateUpload(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* وصف الإعلان */}
        <div className="col-span-2">
          <label className="block text-gray-600 font-semibold mb-1">
            وصف الإعلان:
          </label>
          <textarea
            value={offerDescription}
            onChange={(e) => setOfferDescription(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* تغيير الصورة */}
        <div className="w-full">
          <label className="block text-gray-600 font-semibold mb-2">
            الصورة:
          </label>

          <div className="flex items-center space-x-4">
            <label className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition">
              اختر صورة جديدة
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
            </label>

            {preview && (
              <img
                src={preview}
                alt="معاينة الصورة"
                className="w-20 h-20 object-cover rounded-lg border shadow"
              />
            )}
          </div>
        </div>

        {/* زر حفظ التعديلات */}
        <div className="col-span-2">
          <button
            type="submit"
            disabled={loading}
            className="w-full p-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition"
          >
            {loading ? "جاري التحديث..." : "حفظ التعديلات"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditOffer;
