import React, { useState } from "react";
import { db } from "../firebase";
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-hot-toast";

const AddOffer: React.FC = () => {
  const [offerName, setOfferName] = useState("");
  const [offerType, setOfferType] = useState("");
  const [offerDescription, setOfferDescription] = useState("");
  const [dateUpload, setDateUpload] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const [preview, setPreview] = useState<string | null>(null);
  const storage = getStorage();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !offerName ||
      !offerType ||
      !offerDescription ||
      !dateUpload ||
      !image
    ) {
      toast.error("يرجى ملء جميع الحقول!", {
        duration: 3000,
        position: "top-right",
        style: {
          backgroundImage:
            "linear-gradient(to top, rgb(10, 113, 132), rgb(38, 55, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });
      return;
    }

    setLoading(true);
    try {
      // **1️⃣ رفع الصورة إلى Firebase Storage داخل المجلد `/Images/offers/`**
      const imageRef = ref(storage, `/Images/offers/${image.name}`);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      // **2️⃣ إنشاء وثيقة وسيطة داخل `Images`**
      const imageDocRef = doc(collection(db, "Images")); // 🔹 إنشاء وثيقة داخل `Images`
      await setDoc(imageDocRef, { imageUrl }); // ✅ حفظ رابط الصورة داخل الوثيقة

      // **3️⃣ إضافة بيانات الإعلان إلى كولكشن `offers` مع حفظ رابط الصورة في `ImageId`**
      await addDoc(collection(db, "offers"), {
        offername: offerName,
        offertype: offerType,
        overdescription: offerDescription,
        dateupload: new Date(dateUpload),
        ImageId: imageUrl, // ✅ تخزين رابط الصورة مباشرة
      });

      toast.success("تم إضافة الإعلان بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: {
          backgroundImage:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });

      // **إعادة تعيين القيم بعد الإرسال**
      setOfferName("");
      setOfferType("");
      setOfferDescription("");
      setDateUpload("");
      setImage(null);
      setPreview(null);
    } catch (error) {
      console.error("❌ خطأ في إضافة الإعلان:", error);
      toast.error("حدث خطأ أثناء الإضافة، حاول مجدداً!", {
        duration: 3000,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto my-10 bg-white p-8 rounded-xl shadow-lg">
      <h1
        className="text-2xl font-bold text-gray-700 text-center mb-6 font-tajawal"
        style={{ fontFamily: "Tajawal, sans-serif" }}
      >
        إضافة إعلان جديد
      </h1>
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
        <div>
          <label
            className="block text-gray-600 font-semibold mb-1"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          >
            اسم الإعلان:
          </label>
          <input
            style={{ fontFamily: "Tajawal, sans-serif" }}
            type="text"
            value={offerName}
            onChange={(e) => setOfferName(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label
            className="block text-gray-600 font-semibold mb-1"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          >
            نوع الإعلان:
          </label>
          <input
            type="text"
            style={{ fontFamily: "Tajawal, sans-serif" }}
            value={offerType}
            onChange={(e) => setOfferType(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="col-span-2">
          <label
            className="block text-gray-600 font-semibold mb-1"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          >
            الوصف:
          </label>
          <textarea
            value={offerDescription}
            style={{ fontFamily: "Tajawal, sans-serif" }}
            onChange={(e) => setOfferDescription(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label
            className="block text-gray-600 font-semibold mb-1"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          >
            تاريخ الإضافة:
          </label>
          <input
            style={{ fontFamily: "Tajawal, sans-serif" }}
            type="date"
            value={dateUpload}
            onChange={(e) => setDateUpload(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="w-full">
          <label
            className="block text-gray-600 font-semibold mb-2"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          >
            الصورة:
          </label>

          {/* حاوية اختيار الصورة */}
          <div className="flex items-center space-x-4">
            <label
              className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition"
              style={{ fontFamily: "Tajawal, sans-serif" }}
            >
              اختر صورة
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
            </label>

            {preview && (
              <img
                style={{ fontFamily: "Tajawal, sans-serif" }}
                src={preview}
                alt="معاينة الصورة"
                className="w-20 h-20 object-cover rounded-lg border shadow"
              />
            )}
          </div>
        </div>

        <div className="col-span-2">
          <button
            style={{ fontFamily: "Tajawal, sans-serif" }}
            type="submit"
            disabled={loading}
            className="w-full p-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition"
          >
            {loading ? "جاري الإضافة..." : "إضافة الإعلان"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddOffer;
