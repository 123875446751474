import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // استيراد Firebase
import "../screenstyles/Homecard.css"; // استدعاء الأنماط

interface CardProps {
  title: string; // عنوان الكارت
  description: string; // وصف الكارت
  accountType?: string; // نوع الحساب المطلوب جلبه (اختياري)
  icon: React.ReactElement; // أيقونة الكارت
  gradientColor: string; // لون التدرج
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  accountType,
  icon,
  gradientColor,
}) => {
  const [count, setCount] = useState<number>(0); // عدد الحسابات

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersCollection = collection(db, "users");
        let q;

        // إذا كان accountType فارغًا، اجلب جميع المستندات
        if (!accountType) {
          q = query(usersCollection);
        } else {
          q = query(usersCollection, where("accountType", "==", accountType));
        }

        const snapshot = await getDocs(q);
        setCount(snapshot.size); // تحديث العدد
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accountType]);

  return (
    <div className="card" style={{ background: gradientColor }}>
      <div className="card-header">
        {icon}
        <label className="card-title">{title}</label>
      </div>
      <p className="card-description">{description}</p>
      <p className="card-count">{count}</p>
    </div>
  );
};

export default Card;
