import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { Modal, Box, Typography, Button } from "@mui/material";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSearch, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface Tag {
  id: string;
  name: string;
  ImageUrl: string;
}

const TagsList: React.FC = () => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedTagId, setSelectedTagId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();

  // ✅ **جلب العلامات من Firestore**
  const fetchTags = async () => {
    setLoading(true);
    try {
      const tagsCollection = collection(db, "tags");
      const tagsDocs = await getDocs(tagsCollection);

      const fetchedTags: Tag[] = [];
      tagsDocs.forEach((doc) => {
        fetchedTags.push({
          id: doc.id,
          name: doc.data().name || "بدون اسم",
          ImageUrl: doc.data().ImageUrl || "", // ✅ إضافة الصورة
        });
      });

      console.log("✅ Processed Tags:", fetchedTags);
      setTags(fetchedTags);
      setLoading(false);
    } catch (error) {
      console.error("❌ Error fetching tags:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  // ✅ **فتح المودال عند الضغط على زر الحذف**
  const handleOpenModal = (id: string) => {
    setSelectedTagId(id);
    setOpenModal(true);
  };

  // ✅ **إغلاق المودال**
  const handleCloseModal = () => {
    setSelectedTagId(null);
    setOpenModal(false);
  };

  // ✅ **حذف العلامة من Firestore**
  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, "tags", id));
      toast.success("تم حذف العلامة بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: {
          backgroundImage:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });

      fetchTags();
      handleCloseModal();
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  // ✅ **فلترة العلامات بناءً على البحث**
  const filteredTags = tags.filter(
    (tag) =>
      tag.name && tag.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="product-container">
      {/* ✅ **شريط البحث وزر الإضافة** */}
      <div className="header-container">
        <div className="search-wrapper">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input
            type="text"
            placeholder="ابحث عن العلامات..."
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <button
          className="add-button"
          onClick={() => navigate("/dashboard/add-tag")}
        >
          إضافة علامة جديدة
        </button>
      </div>

      {/* ✅ **عرض العلامات داخل جدول** */}
      {loading ? (
        <p className="text-center">جاري تحميل البيانات...</p>
      ) : (
        <div className="table-container">
          <table className="product-table">
            <thead className="color-table">
              <tr>
                <th>#</th>
                <th>الصورة</th>
                <th>اسم العلامة</th>
                <th>الإجراء</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredTags.map((tag, index) => (
                <tr key={tag.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={tag.ImageUrl}
                      alt={tag.name}
                      className="product-image"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </td>
                  <td>{tag.name}</td>
                  <td>
                    <div className="icon-container">
                      <button
                        className="icon-button"
                        onClick={() =>
                          navigate(`/dashboard/edit-tag/${tag.id}`)
                        }
                      >
                        <FontAwesomeIcon icon={faEdit} title="تعديل العلامة" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="icon-container">
                      <button
                        className="icon-button"
                        onClick={() => handleOpenModal(tag.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} title="حذف العلامة" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ✅ **مودال تأكيد الحذف** */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            هل أنت متأكد من أنك تريد حذف هذه العلامة؟
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => selectedTagId && handleDelete(selectedTagId)}
            >
              نعم، احذف العلامة
            </Button>
            <Button variant="outlined" onClick={handleCloseModal}>
              إلغاء
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default TagsList;
