import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  deleteDoc,
  addDoc,
  doc,
} from "firebase/firestore";
import { toast } from "react-hot-toast";

import "../screenstyles/Product.css"; // استدعاء الأنماط
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faSearch,
  faKey,
  faClipboard,
  faArrowUp,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Box, Typography, Button } from "@mui/material";

interface Product {
  id: string;
  code: string;
  name: string;
  price: string;
  rating: string;
  productDescription: string;
  imageUrl: string;
  location: string;
  Quantity: number;
  points: string;
}

const Product: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  // Fetch products
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const productsCollection = collection(db, "product");
      const productDocs = await getDocs(productsCollection);

      const fetchedProducts: Product[] = [];
      productDocs.forEach((doc) => {
        fetchedProducts.push({
          id: doc.id,
          ...(doc.data() as Omit<Product, "id">),
        });
      });

      setProducts(fetchedProducts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Filter products based on search query
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleEdit = (id: string) => {
    navigate(`/dashboard/edit-product/${id}`);
  };
  const handleDeleteWithConfirmation = (id: string) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirmed) {
      handleDelete(id); // استدعاء دالة الحذف الفعلية
    }
  };

  const addSerial = (id: string) => {
    navigate(`/dashboard/addSerial/${id}`);
  };
  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, "product", id)); // حذف المنتج من قاعدة البيانات
      toast.success("تم حذف الحساب!", {
        duration: 3000,
        position: "top-right",
        style: {
          backgroundImage:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });
      handleCloseModal();
      fetchProducts(); // تحديث القائمة بعد الحذف
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };
  const handleOpenModal = (id: string) => {
    setSelectedUserId(id);
    setOpenModal(true);
  };
  // إغلاق المودال بدون حذف الحساب
  const handleCloseModal = () => {
    setSelectedUserId(null);
    setOpenModal(false);
  };
  return (
    <div className="w-full mx-auto bg-white rounded-xl shadow-lg ">
      {/* Search and Add New Product */}

      <div className="max-w-full flex items-center justify-between bg-gradient-to-r from-[#197184] to-[#269BB4] text-white px-6 py-3 rounded-t-lg shadow-md">
        {/* أيقونة الرجوع للخلف */}

        {/* العنوان */}
        <h1
          className="text-xl font-bold flex-1"
          style={{ fontFamily: "Tajawal, sans-serif" }}
        >
          الــمــنــتــجــات
        </h1>

        <div className="flex items-center bg-gray-100 rounded-full px-4 py-1 w-72 shadow-md ml-2">
          <FontAwesomeIcon icon={faSearch} className="text-gray-500 mr-2" />
          <input
            type="text"
            placeholder="البحث في المنتجات..."
            className="bg-transparent focus:outline-none w-full text-sm text-gray-700 py-1 font-custom"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* أيقونة إعادة تعيين الحقول */}
        <FontAwesomeIcon
          icon={faAdd}
          size="lg"
          className="cursor-pointer hover:opacity-80 transition ml-4"
          onClick={() => navigate("/add-product")}
        />
      </div>

      {/* Product Table */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="table-container">
          <table className="product-table">
            <thead className="color-table">
              <tr>
                <th>#</th>
                <th className="price-column">الصورة</th>
                <th className="price-column">الاسم</th>
                <th className="price-column">السعر</th>
                <th className="price-column">التقييم</th>
                <th className="price-column">الكمية</th>
                <th className="price-column">النقاط</th>
                <th className="price-column">الاجراءات</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product, index) => (
                <tr key={product.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={product.imageUrl}
                      alt={product.name}
                      className="product-image"
                    />
                  </td>
                  <td style={{ fontSize: 16 }}>{product.name}</td>
                  <td style={{ fontSize: 16 }}>${product.price}</td>
                  <td>{product.rating} ⭐</td>
                  <td style={{ fontSize: 16 }}>{product.Quantity}</td>
                  <td style={{ fontSize: 16 }}>{product.points}</td>
                  <td>
                    <div className="icon-container">
                      <button
                        className="icon-button"
                        onClick={() => handleEdit(product.id)}
                      >
                        <FontAwesomeIcon icon={faEdit} title="Edit" />
                      </button>

                      <button
                        className="icon-button"
                        onClick={() => handleOpenModal(product.id)}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          title="Delete"
                          style={{ color: "red" }}
                        />
                      </button>
                      {/* <button
                        className="icon-button"
                        onClick={() => addSerial(product.id)}
                      >
                        <FontAwesomeIcon icon={faKey} title="Add Serial" />
                      </button>
                      <button className="icon-button">
                        <FontAwesomeIcon
                          icon={faClipboard}
                          title="View Serials"
                        />
                      </button> */}
                      {/* <button className="icon-button">
                        <FontAwesomeIcon
                          icon={faArrowUp}
                          title="Move Product"
                        />
                      </button> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ✅ مودال تأكيد الحذف */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            هل أنت متأكد من أنك تريد حذف هذا المادة؟
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => selectedUserId && handleDelete(selectedUserId)}
            >
              نعم، احذف المادة؟
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCloseModal}
            >
              إلغاء
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Product;
