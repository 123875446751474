import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  IconButton,
  Collapse,
  Typography,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  Dashboard as DashboardIcon,
  BarChart as AnalyticsIcon,
  Settings as SettingsIcon,
  Person as ProfileIcon,
  Label as TagIcon,
  AdUnits as AdsIcon,
  AccountCircle as AccountIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  Brightness2 as MoonIcon,
  WbSunny as SunIcon,
  DeviceHub as DeviceIcon,
  MoneySharp as MoneySharp,
  AdsClick as AddSharp,
  ExitToApp as ExitToAppIcon,
  ExpandLess,
  ExpandMore,
  Group as UsersIcon,
  Security as PermissionsIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom"; // ✅ استيراد useNavigate
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

interface SidebarProps {
  onLogout: () => void; // ✅ استلام دالة تسجيل الخروج
}
const Sidebar: React.FC<SidebarProps> = ({ onLogout }) => {
  const [openUsersMenu, setOpenUsersMenu] = useState(false);
  const navigate = useNavigate(); // ✅ تهيئة التنقل
  const [open, setOpen] = useState(false); // ✅ التحكم في إظهار المودل

  const toggleUsersMenu = () => {
    setOpenUsersMenu((prev) => !prev);
  };
  const handleLogout = () => {
    onLogout(); // 🔹 استدعاء دالة تسجيل الخروج القادمة من `App.tsx`
    navigate("/login"); // 🔹 توجيه المستخدم إلى صفحة تسجيل الدخول
  };

  const handleOpen = () => {
    setOpen(true); // فتح المودل
  };

  const handleClose = () => {
    setOpen(false); // إغلاق المودل بدون تسجيل خروج
  };

  const confirmLogout = () => {
    handleLogout(); // تنفيذ عملية تسجيل الخروج
    setOpen(false); // إغلاق المودل
  };

  const links = [
    { name: "الرئيسية", path: "/", icon: <DashboardIcon />, color: "#1abc9c" },
    {
      name: "الحسابات",
      path: "/Account",
      icon: <DeviceIcon />,
    },
    { name: "إدارة المواد", path: "/Product", icon: <MoneySharp /> },

    {
      name: "الاعلانات",
      path: "/OffersList",
      icon: <MoneySharp />,
    },

    { name: "الفئات", path: "/Facet", icon: <AddSharp /> },
    {
      name: "إدارة العلامات",
      path: "/tags",
      icon: <AnalyticsIcon />,
    },
    {
      name: "الطلبات",
      path: "/CompleteOrdersList",
      icon: <AnalyticsIcon />,
      hasSubmenu: true,
      submenu: [
        { name: "الطلبات غير المكتملة", path: "/users", icon: <UsersIcon /> },
        {
          name: "الطلبات المكتملة",
          path: "/CompleteOrdersList",
          icon: <SettingsIcon />,
        },
      ],
    },

    { name: "الدعم الفني", path: "/profile", icon: <ProfileIcon /> },

    { name: "الملف الشخصي", path: "/profile", icon: <ProfileIcon /> },
  ];

  return (
    <Box
      sx={{ display: "flex", height: "100vh", flexDirection: "row-reverse" }}
    >
      <Drawer
        variant="permanent"
        sx={{
          "& .MuiDrawer-paper": {
            width: 240,
            background: `linear-gradient(to top, rgb(25 113 132), rgb(38 155 180))`,
            color: "#fff",
            position: "fixed",
            height: "100vh",
            overflowY: "auto",
            right: 0,
          },
        }}
        open
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Avatar
            sx={{ width: 70, height: 70, marginBottom: "10px" }}
            alt="صورة المستخدم"
            src="https://via.placeholder.com/150"
          />
          <Typography
            variant="subtitle1"
            sx={{ color: "#fff", fontFamily: "tajawajmedium" }}
          >
            أيوب
          </Typography>
        </Box>

        <List>
          {links.map((link, index) => (
            <React.Fragment key={index}>
              {/* ✅ القائمة التي تحتوي على عناصر فرعية */}
              {link.hasSubmenu ? (
                <>
                  <ListItem
                    component="div"
                    onClick={toggleUsersMenu}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: "8px 12px",
                      cursor: "pointer",
                    }}
                  >
                    <ListItemIcon sx={{ color: "#fff" }}>
                      {link.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={link.name}
                      sx={{
                        color: "#fff",
                        textAlign: "right",
                        fontSize: "14px",
                      }}
                    />
                    {openUsersMenu ? (
                      <ExpandLess sx={{ color: "#fff" }} />
                    ) : (
                      <ExpandMore sx={{ color: "#fff" }} />
                    )}
                  </ListItem>

                  <Collapse in={openUsersMenu} timeout="auto" unmountOnExit>
                    <List
                      component="div"
                      disablePadding
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "5px",
                      }}
                    >
                      {link.submenu.map((subLink, subIndex) => (
                        <ListItem
                          key={subIndex}
                          component={Link}
                          to={subLink.path}
                          sx={{
                            paddingLeft: "32px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "10",
                          }}
                        >
                          <ListItemIcon sx={{ color: "#fff" }}>
                            {subLink.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={subLink.name}
                            sx={{
                              color: "#fff",
                              textAlign: "right",
                              fontSize: "10px",
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                // ✅ الروابط العادية
                <ListItem
                  component={Link}
                  to={link.path}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "8px 12px",
                  }}
                >
                  <ListItemIcon sx={{ color: "#fff" }}>
                    {link.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={link.name}
                    sx={{ color: "#fff", textAlign: "right", fontSize: "14px" }}
                  />
                </ListItem>
              )}
            </React.Fragment>
          ))}
        </List>

        <Box
          sx={{
            position: "absolute", // أو "fixed" إذا كنت تريد أن يظل ثابتًا أثناء التمرير
            right: 0, // جعله بمحاذاة اليمين
            bottom: 0, // جعله في الأسفل
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handleOpen}>
            <ExitToAppIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
      </Drawer>
      {/* ✅ المودل لتأكيد تسجيل الخروج */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>تأكيد تسجيل الخروج</DialogTitle>
        <DialogContent>
          <DialogContentText>
            هل أنت متأكد أنك تريد تسجيل الخروج؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            إلغاء
          </Button>
          <Button onClick={confirmLogout} color="secondary" variant="contained">
            تسجيل الخروج
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Sidebar;
