import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // لاستيراد useParams
import { db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import "../screenstyles/AddSerial.css"; // استدعاء الأنماط

const AddSerial: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // استخراج id من المعلمات
  const [points, setPoints] = useState<number>(0);
  const [barcode, setBarcode] = useState<string>("");

  // Fetch points from the product collection
  const fetchProductPoints = async () => {
    try {
      if (!id) {
        alert("No product ID provided!");
        return;
      }

      const productDoc = await getDoc(doc(db, "product", id));
      if (productDoc.exists()) {
        const productData = productDoc.data();
        setPoints(productData?.points || 0); // جلب النقاط
      } else {
        alert("Product not found!");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  // Add Serial Number to SerialNumber collection
  const addSerialNumber = async () => {
    if (!barcode.trim()) {
      alert("Please scan or enter a valid barcode.");
      return;
    }

    try {
      await setDoc(doc(db, "SerailNumber", barcode), {
        productId: id,
        point: points,
      });

      alert("Serial number added successfully!");
      setBarcode(""); // إعادة تعيين الباركود
    } catch (error) {
      console.error("Error adding serial number:", error);
    }
  };

  useEffect(() => {
    fetchProductPoints(); // جلب النقاط عند تحميل المكون
  }, [id]);

  return (
    <div className="add-serial-container">
      <h1>Add Serial Number</h1>
      <div className="form-group">
        <label>Points:</label>
        <input
          type="number"
          value={points}
          onChange={(e) => setPoints(Number(e.target.value))}
        />
      </div>
      <div className="form-group">
        <label>Scan or Enter Barcode:</label>
        <input
          type="text"
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
          placeholder="Scan or type barcode here"
        />
      </div>
      <button onClick={addSerialNumber}>Add Serial Number</button>
    </div>
  );
};

export default AddSerial;
