// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB8_pwAb3tipMqejkmJKYbONCVwtBgnRAE",
  authDomain: "shoofmangmentapplcation.firebaseapp.com",
  projectId: "shoofmangmentapplcation",
  storageBucket: "shoofmangmentapplcation.appspot.com",
  messagingSenderId: "606306769569",
  appId: "1:606306769569:web:204569c837d003f4422ee0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

const auth = getAuth(app);

// Export Firestore instance
export { db, auth };
