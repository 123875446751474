import React, { useState } from "react";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { toast } from "react-hot-toast";

const AddFacet: React.FC = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  // ✅ دالة لإضافة الـ Facet إلى Firestore
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name.trim()) {
      toast.error("يرجى إدخال اسم الفئة!", {
        duration: 3000,
        position: "top-right",
        style: {
          backgroundImage:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });
      return;
    }

    setLoading(true);
    try {
      // ✅ إضافة بيانات الفئة إلى Firestore
      await addDoc(collection(db, "facets"), { name });

      toast.success("تمت إضافة الفئة بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: {
          backgroundImage:
            "linear-gradient(to top, rgb(10, 113, 132), rgb(38, 55, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });

      setName(""); // إعادة تعيين الحقل بعد الحفظ
    } catch (error) {
      console.error("خطأ في إضافة الفئة:", error);
      toast.error("حدث خطأ أثناء الإضافة، حاول مرة أخرى!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto my-10 bg-white p-8 rounded-xl shadow-lg">
      <h1
        className="text-2xl font-bold text-gray-700 text-center mb-6"
        style={{ fontFamily: "Tajawal, sans-serif" }}
      >
        إضافة فئة جديدة
      </h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* حقل إدخال الاسم */}
        <div>
          <label
            className="block text-gray-600 font-semibold mb-2"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          >
            اسم الفئة:
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          />
        </div>

        {/* زر الإرسال */}
        <div>
          <button
            type="submit"
            disabled={loading}
            className="w-full p-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition"
            style={{ fontFamily: "Tajawal, sans-serif" }}
          >
            {loading ? "جاري الإضافة..." : "إضافة الفئة"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddFacet;
