import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import "../screenstyles/Login.css"; // استدعاء ملف الأنماط
import { useNavigate } from "react-router-dom";
import splashImage from "../assets/images/splashImage4.png";

interface LoginPageProps {
  onLogin: (authToken: string) => void; // تمرير `onLogin` كـ prop
}

const Login: React.FC<LoginPageProps> = ({ onLogin }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate(); // Hook للتنقل بين الواجهات

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // ✅ احصل على التوكن
      const token = await userCredential.user.getIdToken();

      console.log("User logged in:", userCredential.user);

      // ✅ تحديث حالة المصادقة
      onLogin(token);

      // ✅ الانتقال إلى Dashboard بعد تحديث `isAuthenticated`
      navigate("/");
    } catch (err: any) {
      console.error("Error logging in:", err.message);
      setError("خطأ في تسجيل الدخول، تأكد من البريد وكلمة المرور!");
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        {/* القسم الأول: الصورة */}
        <div className="login-image-section">
          <img src={splashImage} alt="Login" className="login-image" />
        </div>

        {/* القسم الثاني: نموذج تسجيل الدخول */}
        <div className="login-form-section">
          <h1 className="login-title">تسجيل الدخول</h1>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleLogin}>
            <div className="input-group">
              <label className="input-label">البريد الإلكتروني:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="input-field"
              />
            </div>
            <div className="input-group">
              <label className="input-label">كلمة المرور:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="input-field"
              />
            </div>
            <button type="submit" className="submit-button">
              تسجيل الدخول
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
