import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import "../screenstyles/OrderItems.css";

interface Product {
  id: string;
  name: string;
  imageUrl: string;
  code: string;
  price: string;
  productDescription: string;
  rating: string;
}

interface Item {
  id: string;
  productId: string;
  quantity: number;
  totalPrice: number;
  itemData?: Product;
}

const OrderItems: React.FC = () => {
  const { userId, orderId } = useParams();
  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();

  // ✅ **جلب بيانات المواد داخل الطلب**
  const fetchOrderItems = async () => {
    setLoading(true);
    try {
      const itemsCollection = collection(
        db,
        "Order",
        userId as string,
        "CompleteOrder",
        orderId as string,
        "items"
      );
      const itemsDocs = await getDocs(itemsCollection);

      const fetchedItems: Item[] = [];

      for (const docSnapshot of itemsDocs.docs) {
        const data = docSnapshot.data();
        const productId = docSnapshot.id; // ✅ استخدام ID المستند كـ productId

        let itemData: Product | undefined;

        // ✅ **جلب بيانات المنتج من كولكشن `product` باستخدام `docSnapshot.id` بدلاً من `productId`**
        const productDocRef = doc(db, "product", productId);
        const productDoc = await getDoc(productDocRef);

        if (productDoc.exists()) {
          const productData = productDoc.data();
          itemData = {
            id: productDoc.id,
            name: productData.name || "غير متوفر",
            imageUrl: productData.imageUrl || "",
            code: productData.code || "غير متوفر",
            price: productData.price || "غير متوفر",
            productDescription: productData.productDescription || "غير متوفر",
            rating: productData.rating || "غير متوفر",
          };
        }

        fetchedItems.push({
          id: docSnapshot.id,
          productId,
          quantity: data.quantity || 1,
          totalPrice: data.totalPrice || 0,
          itemData,
        });
      }

      setItems(fetchedItems);
      setLoading(false);
    } catch (error) {
      console.error("❌ Error fetching order items:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userId || !orderId) {
      console.error("❌ Error: userId or orderId is undefined.");
      return;
    }
    fetchOrderItems();
  }, [userId, orderId]);

  return (
    <div className="product-container">
      {/* ✅ **شريط البحث وزر الرجوع** */}
      <div className="header-container" style={{ padding: 10 }}>
        <div className="search-wrapper" style={{ margin: 10 }}>
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input
            type="text"
            placeholder="البحث في المنتجات"
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <button className="add-button" onClick={() => navigate(-1)}>
          رجوع
        </button>
      </div>
      <h2 className="text-center text-lg font-bold mb-4">
        قائمة المواد الخاصة بالطلب
      </h2>

      {/* ✅ **عرض بيانات المنتجات داخل جدول** */}
      {loading ? (
        <p className="text-center">جاري تحميل البيانات...</p>
      ) : items.length === 0 ? (
        <p className="text-center">لا يوجد مواد في هذا الطلب.</p>
      ) : (
        <div className="table-container">
          <table className="product-table">
            <thead>
              <tr>
                <th>#</th>
                <th>صورة المنتج</th>
                <th>اسم المنتج</th>
                <th>الكود</th>
                <th>السعر</th>
                <th>الكمية</th>
                <th>إجمالي السعر</th>
                <th>الوصف</th>
                <th>التقييم</th>
              </tr>
            </thead>
            <tbody>
              {items
                .filter((item) =>
                  item.itemData?.name
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                )
                .map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>
                      {item.itemData?.imageUrl ? (
                        <img
                          src={item.itemData?.imageUrl}
                          alt={item.itemData?.name}
                          className="product-image"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "5px",
                          }}
                        />
                      ) : (
                        "لا توجد صورة"
                      )}
                    </td>
                    <td>{item.itemData?.name}</td>
                    <td>{item.itemData?.code}</td>
                    <td>{item.itemData?.price} $</td>
                    <td>{item.quantity}</td>
                    <td>{item.totalPrice} $</td>
                    <td>{item.itemData?.productDescription}</td>
                    <td>{item.itemData?.rating} ⭐</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default OrderItems;
