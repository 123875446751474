import React, { ReactNode } from "react";
import { useSidebar } from "../contexts/SidebarContext";

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { isOpen } = useSidebar();
  const sidebarWidth = isOpen ? 240 : 80; // عرض الشريط الجانبي بناءً على حالته

  return (
    <div className="flex min-h-screen bg-gray-500">
      {/* الشريط الجانبي */}
      <div
        className="h-full bg-white shadow-lg transition-all duration-300"
        style={{
          width: `${sidebarWidth}px`, // العرض الثابت للشريط الجانبي
          flexShrink: 0, // يمنع تقليص عرض الشريط الجانبي
        }}
      ></div>

      {/* المحتوى الرئيسي */}
      <div
        className="flex-grow  w-screen h-auto  transition-all duration-300 p-2"
        style={{
          display: "flex",
          backgroundColor: "white",
        }}
      >
        <main className="shadow-md rounded-lg w-full max-w-6xl mr-4">
          {children}
        </main>
      </div>
    </div>
  );
};
export default Layout;
