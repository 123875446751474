import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "../screen/Login";
import Dashboard from "../screen/Home";
import Sidebar from "../layouts/Sidebar";
import Layout from "../layouts/Layout";
import Account from "../screen/Account";
import Product from "../screen/Product";
import EditProduct from "../screen/EditProduct";
import AddSerial from "../screen/AddSerial";
import AddProduct from "../screen/AddProduct";
import OffersList from "../screen/OffersList";
import AddOffer from "../screen/AddOffer";
import { EditOff } from "@mui/icons-material";
import EditOffer from "../screen/EditOffer";
import FacetsList from "../screen/FacetsList";
import AddFacet from "../screen/AddFacet";
import EditFacet from "../screen/EditFacet";
import TagsList from "../screen/TagsList";
import AddTag from "../screen/AddTag";
import CompleteOrdersList from "../screen/CompleteOrdersList";
import UserOrders from "../screen/UserOrders";
import OrderItems from "../screen/OrderItems";
interface AppRoutesProps {
  isAuthenticated: boolean;
  onLogin: (authToken: string) => void;
  onLogout: () => void;
}

const AppRoutes: React.FC<AppRoutesProps> = ({
  isAuthenticated,
  onLogin,
  onLogout,
}) => {
  return (
    <Router>
      {isAuthenticated ? (
        <div className="flex">
          <Sidebar onLogout={onLogout} />
          <Layout>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/Account" element={<Account />} />
              <Route path="/Product" element={<Product />} />
              <Route
                path="/dashboard/edit-product/:id"
                element={<EditProduct />}
              />
              <Route path="/dashboard/addSerial/:id" element={<AddSerial />} />
              <Route path="/add-product" element={<AddProduct />} />
              <Route path="/OffersList" element={<OffersList />} />
              <Route path="/AddOffer" element={<AddOffer />} />
              <Route path="/dashboard/edit-offer/:id" element={<EditOffer />} />
              <Route path="/Facet" element={<FacetsList />} />
              <Route path="/dashboard/add-facet" element={<AddFacet />} />
              <Route path="/dashboard/edit-facet/:id" element={<EditFacet />} />
              <Route path="/tags" element={<TagsList />} />
              <Route path="/dashboard/add-tag" element={<AddTag />} />
              <Route
                path="/CompleteOrdersList"
                element={<CompleteOrdersList />}
              />
              <Route
                path="/dashboard/user-orders/:userId"
                element={<UserOrders />}
              />
              <Route
                path="/dashboard/user-orders/:userId/:orderId"
                element={<OrderItems />}
              />

              {/* ✅ إضافة مسار جديد */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Layout>
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<Login onLogin={onLogin} />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );
};

export default AppRoutes;
