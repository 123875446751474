import { createContext, useContext, useState } from "react";

// تعريف السياق
const SidebarContext = createContext({
  isOpen: true,
  toggleSidebar: () => {},
  darkMode: false,
  toggleDarkMode: () => {},
});

// مزود السياق
export const SidebarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(false);

  const toggleSidebar = () => setIsOpen(!isOpen);
  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        toggleSidebar,
        darkMode,
        toggleDarkMode,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

// استخدام السياق
export const useSidebar = () => useContext(SidebarContext);
