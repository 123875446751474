import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faTrash,
  faSearch,
  faEdit,
  faShower,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

interface Order {
  userId: string;
  userName: string;
  userPhone: string;
  userImage: string;
  totalOrders: number;
  totalPrice: number;
  ordersDetails: string; // لتجميع تفاصيل الطلبات في حقل واحد
}

const CompleteOrdersList: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();

  // ✅ **جلب الطلبات المكتملة وبيانات المستخدم**
  const fetchOrders = async () => {
    setLoading(true);
    try {
      const ordersCollection = collection(db, "Order");
      const ordersDocs = await getDocs(ordersCollection);

      const fetchedOrders: Order[] = [];

      for (const orderDoc of ordersDocs.docs) {
        const userId = orderDoc.id; // هذا هو معرف المستخدم الذي قدم الطلب
        const completeOrderCollection = collection(
          db,
          "Order",
          userId,
          "CompleteOrder"
        );
        const completeOrderDocs = await getDocs(completeOrderCollection);

        if (completeOrderDocs.empty) continue; // إذا لم يكن هناك طلبات، نتخطى هذا المستخدم

        // ✅ **جلب بيانات المستخدم**
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);
        let userName = "غير معروف";
        let userPhone = "غير متوفر";
        let userImage = "";

        if (userDoc.exists()) {
          const userData = userDoc.data();
          userName = userData.fullName || "غير معروف";
          userPhone = userData.phoneNumber || "غير متوفر";
          userImage = userData.profileImageUrl || "";
        }

        // ✅ **تجميع بيانات الطلبات الخاصة بالمستخدم**
        let totalOrders = 0;
        let totalPrice = 0;
        let ordersDetailsArray: string[] = [];

        completeOrderDocs.forEach((docSnapshot) => {
          const data = docSnapshot.data();
          totalOrders += 1;
          totalPrice += data.totalOrderPrice || 0;

          ordersDetailsArray.push(
            `🔹 ${data.locationName || "غير متوفر"} - ${
              data.PaymentStutes || "غير متوفر"
            } - ${data.Stutes || "غير متوفر"}`
          );
        });

        fetchedOrders.push({
          userId,
          userName,
          userPhone,
          userImage,
          totalOrders,
          totalPrice,
          ordersDetails: ordersDetailsArray.join("\n"), // تحويل المصفوفة إلى نص
        });
      }

      console.log("✅ Processed Orders:", fetchedOrders);
      setOrders(fetchedOrders);
      setLoading(false);
    } catch (error) {
      console.error("❌ Error fetching orders:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  // ✅ **فلترة الطلبات بناءً على البحث**
  const filteredOrders = orders.filter(
    (order) =>
      order.userName &&
      order.userName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="product-container">
      {/* ✅ **شريط البحث** */}
      <div className="header-container">
        <div className="search-wrapper">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input
            type="text"
            placeholder="ابحث بواسطة اسم العميل..."
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      {/* ✅ **عرض الطلبات داخل جدول** */}
      {loading ? (
        <p className="text-center">جاري تحميل البيانات...</p>
      ) : (
        <div className="table-container">
          <table className="product-table">
            <thead className="color-table">
              <tr>
                <th>#</th>
                <th>صورة العميل</th>
                <th>اسم العميل</th>
                <th>رقم الهاتف</th>
                <th>عدد الطلبات</th>
                <th>إجمالي المبلغ</th>
                <th>التفاصيل</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order, index) => (
                <tr key={order.userId}>
                  <td>{index + 1}</td>
                  <td>
                    {order.userImage ? (
                      <img
                        src={order.userImage}
                        alt={order.userName}
                        className="product-image"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    ) : (
                      "لا يوجد صورة"
                    )}
                  </td>
                  <td>{order.userName}</td>
                  <td>{order.userPhone}</td>
                  <td>{order.totalOrders}</td>
                  <td>{order.totalPrice} $</td>

                  <td>
                    <div className="icon-container">
                      <button
                        className="icon-button"
                        onClick={() =>
                          navigate(`/dashboard/user-orders/${order.userId}`)
                        }
                      >
                        <FontAwesomeIcon icon={faEye} title="عرض الطلبات" />
                      </button>
                    </div>
                  </td>

                  {/* <td>
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        fontFamily: "monospace",
                      }}
                    >
                      {order.ordersDetails}
                    </pre>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CompleteOrdersList;
