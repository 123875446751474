import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  deleteDoc,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import "../screenstyles/Product.css"; // استدعاء الأنماط
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faSearch,
  faKey,
  faClipboard,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { Style } from "@mui/icons-material";
import { Modal, Box, Typography, Button } from "@mui/material";
import { toast } from "react-hot-toast";

interface Account {
  id: string;
  about: string;
  accountType: string;
  dateOfBirth: string;
  defaultAddress: string;
  email: string;
  fullName: string;
  gender: string;
  phoneNumber: string;
  points: number;
  profileImageUrl: string;
  rating: string;
}

const Account: React.FC = () => {
  const [products, setProducts] = useState<Account[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();

  // Fetch products
  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const usersCollection = collection(db, "users");
      const userDocs = await getDocs(usersCollection);

      const fetchedAccounts: Account[] = [];
      userDocs.forEach((doc) => {
        fetchedAccounts.push({
          id: doc.id,
          ...(doc.data() as Omit<Account, "id">),
        });
      });

      setProducts(fetchedAccounts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching accounts:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  // فتح المودال عند الضغط على زر الحذف
  const handleOpenModal = (id: string) => {
    setSelectedUserId(id);
    setOpenModal(true);
  };
  // إغلاق المودال بدون حذف الحساب
  const handleCloseModal = () => {
    setSelectedUserId(null);
    setOpenModal(false);
  };

  // Filter products based on search query
  //   const filteredProducts = products.filter((product) =>
  //     product.fullName.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  const handleEdit = (id: string) => {
    navigate(`/dashboard/edit-product/${id}`);
  };
  const handleDeleteWithConfirmation = (id: string) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Account?"
    );
    if (confirmed) {
      handleDelete(id); // استدعاء دالة الحذف الفعلية
    }
  };
  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, "users", id)); // حذف المنتج من قاعدة البيانات
      toast.success("تم حذف الحساب!", {
        duration: 3000,
        position: "top-right",
        style: {
          backgroundImage:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });

      fetchAccounts(); // تحديث القائمة بعد الحذف
      handleCloseModal();
    } catch (error) {
      console.error("Error deleting Account:", error);
    }
  };
  const [accountTypeFilter, setAccountTypeFilter] = useState<string>("");
  const filteredProducts = products.filter(
    (product) =>
      product.fullName.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (accountTypeFilter === "" || product.accountType === accountTypeFilter)
  );
  const updateAccountType = async (id: string, newType: string) => {
    try {
      const userRef = doc(db, "users", id);
      await updateDoc(userRef, { accountType: newType });
      alert("Account type updated successfully!");
      fetchAccounts(); // تحديث البيانات
    } catch (error) {
      console.error("Error updating account type:", error);
    }
  };

  return (
    <div className="product-container">
      {/* Search and Add New Product */}
      <div className="header-container">
        <div className="search-wrapper">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input
            type="text"
            placeholder="ابحث بواسطة الاسم"
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <select
            className="filter-dropdown"
            value={accountTypeFilter}
            onChange={(e) => setAccountTypeFilter(e.target.value)}
          >
            <option value="">جميع الحسابات</option>
            <option value="مستخدم">مستخدم</option>
            <option value="وكيل">وكيل</option>
            <option value="منصب">منصب</option>
            <option value="طلب وكيل بانتظار الموافقة">
              طلب وكيل بانتظار الموافقة
            </option>
            <option value="طلب منصب بانتظار الموافقة">
              طلب منصب بانتظار الموافقة
            </option>
          </select>
        </div>

        {/* <button className="add-button">Add New Product</button> */}
      </div>

      {/* Product Table */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="table-container">
          <table className="product-table">
            <thead className="color-table">
              <tr>
                <th>#</th>
                <th>الصورة</th>
                <th>الآسم الكامل</th>
                <th>الآيميل</th>
                <th>رقم الهاتف</th>
                <th>نوع الحساب</th>
                <th>التقييم</th>
                <th>النقاط</th>
                <th>تحويل الحالة</th>
                <th>الاجراء</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={user.profileImageUrl}
                      //   alt={user.fullName}
                      className="product-image"
                    />
                  </td>
                  <td>{user.fullName}</td>
                  <td>{user.email}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.accountType}</td>
                  <td>{user.rating} ⭐</td>
                  <td>{user.points}</td>
                  <td>
                    <select
                      value={user.accountType}
                      onChange={(e) =>
                        updateAccountType(user.id, e.target.value)
                      }
                    >
                      <option value="مستخدم">مستخدم</option>
                      <option value="وكيل">وكيل</option>
                      <option value="منصب">منصب</option>
                      <option value="طلب وكيل بانتظار الموافقة">
                        طلب وكيل بانتظار الموافقة
                      </option>
                      <option value="طلب منصب بانتظار الموافقة">
                        طلب منصب بانتظار الموافقة
                      </option>
                    </select>
                  </td>

                  <td>
                    <div className="icon-container">
                      <button
                        className="icon-button"
                        onClick={() => handleEdit(user.id)}
                      >
                        <FontAwesomeIcon icon={faEdit} title="Edit" />
                      </button>
                      <button
                        className="icon-button"
                        onClick={() => handleOpenModal(user.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} title="Delete" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ✅ مودال تأكيد الحذف */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            هل أنت متأكد من أنك تريد حذف هذا الحساب؟
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => selectedUserId && handleDelete(selectedUserId)}
            >
              نعم، احذف الحساب
            </Button>

            <Button variant="outlined" onClick={handleCloseModal}>
              إلغاء
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Account;
