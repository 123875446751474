import React from "react";
import Card from "../components/Card"; // استدعاء الكارت
import "../screenstyles/Homestyle.css"; // ملف CSS للواجهة الرئيسية
import IntroCard from "../components/IntroCard"; // استدعاء الكارت
import Navbar from "../components/Navbar";
import { FaUsers, FaCogs, FaHome } from "react-icons/fa"; // استيراد الأيقونات

const Home: React.FC = () => {
  return (
    <div className="home-container">
      {/* <IntroCard /> */}
      <div className="cards-wrapper">
        {/* الكارت الأول */}
        <Card
          title="الحسابات"
          description="عدد حسابات"
          accountType=""
          icon={<FaUsers size={40} />}
          gradientColor="linear-gradient(to top, rgb(25 113 132), rgb(38 155 180))"
        />

        <Card
          title="المستخدمين"
          description="عدد حسابات المستخدمين"
          accountType="مستخدم"
          icon={<FaUsers size={40} />}
          gradientColor="linear-gradient(to top, rgb(25 113 132), rgb(38 155 180))"
        />

        {/* الكارت الثاني */}
        <Card
          title="التقنيين"
          description="عدد حسابات التقنيين"
          accountType="منصب"
          icon={<FaCogs size={40} />}
          gradientColor="linear-gradient(to top, rgb(25 113 132), rgb(38 155 180))"
        />

        {/* الكارت الثالث */}
        <Card
          title="الوكلاء"
          description="عدد حسابات الوكلاء"
          accountType="وكيل"
          icon={<FaHome size={40} />}
          gradientColor="linear-gradient(to top, rgb(25 113 132), rgb(38 155 180))"
        />

        <Card
          title="طلب انضمام تقني"
          description="الطلبات المقدمة لتطبيق شوف للحصول على وضيفة تقنية"
          accountType="طلب تقني بانتظار الموافقة"
          icon={<FaHome size={40} />}
          gradientColor="linear-gradient(to top, rgb(25 113 132), rgb(38 155 180))"
        />

        <Card
          title="طلب انضمام وكيل"
          description="الطلبات المقدمة لتطبيق شوف للحصول على وكالة"
          accountType="طلب وكيل بانتظار الموافقة"
          icon={<FaHome size={40} />}
          gradientColor="linear-gradient(to top, rgb(25 113 132), rgb(38 155 180))"
        />
      </div>
    </div>
  );
};

export default Home;
