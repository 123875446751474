import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { Modal, Box, Typography, Button } from "@mui/material";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSearch, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface Offer {
  id: string;
  ImageId: string;
  dateupload: any;
  offerforwordid: string;
  offername: string;
  offertype: string;
  overdescription: string;
}

const OffersList: React.FC = () => {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedOfferId, setSelectedOfferId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();

  // ✅ **جلب العروض من Firestore**
  const fetchOffers = async () => {
    setLoading(true);
    try {
      const offersCollection = collection(db, "offers");
      const offersDocs = await getDocs(offersCollection);

      const fetchedOffers: Offer[] = [];
      offersDocs.forEach((doc) => {
        const data = doc.data();
        fetchedOffers.push({
          id: doc.id,
          ImageId: data.ImageId || "",
          dateupload: data.dateupload
            ? new Date(data.dateupload.seconds * 1000).toLocaleDateString()
            : "غير متوفر",
          offerforwordid: data.offerforwordid || "غير متوفر",
          offername: data.offername || "بدون عنوان",
          offertype: data.offertype || "غير محدد",
          overdescription: data.overdescription || "لا يوجد وصف",
        });
      });

      console.log("✅ Processed Offers:", fetchedOffers);
      setOffers(fetchedOffers);
      setLoading(false);
    } catch (error) {
      console.error("❌ Error fetching offers:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  // ✅ **فتح المودال عند الضغط على زر الحذف**
  const handleOpenModal = (id: string) => {
    setSelectedOfferId(id);
    setOpenModal(true);
  };

  // ✅ **إغلاق المودال**
  const handleCloseModal = () => {
    setSelectedOfferId(null);
    setOpenModal(false);
  };

  // ✅ **حذف العرض من Firestore**
  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, "offers", id));
      toast.success("تم حذف العرض بنجاح!", {
        duration: 3000,
        position: "top-right",
        style: {
          backgroundImage:
            "linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180))",
          color: "#fff",
          fontSize: "16px",
        },
      });

      fetchOffers();
      handleCloseModal();
    } catch (error) {
      console.error("Error deleting offer:", error);
    }
  };

  // ✅ **فلترة العروض بناءً على البحث**
  const filteredOffers = offers.filter(
    (offer) =>
      offer.offername &&
      offer.offername.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="product-container">
      {/* ✅ **عنوان الصفحة** */}
      <div className="header-container">
        <div className="search-wrapper">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input
            type="text"
            placeholder="ابحث بواسطة الاسم"
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <button className="add-button" onClick={() => navigate("/AddOffer")}>
          إضافة إعلان جديد
        </button>
        {/* <button className="add-button">Add New Product</button> */}
      </div>

      {/* ✅ **عرض العروض داخل جدول** */}
      {loading ? (
        <p className="text-center">جاري تحميل البيانات...</p>
      ) : (
        <div className="table-container">
          <table className="product-table">
            <thead className="color-table">
              <tr>
                <th>#</th>
                <th>الصورة</th>
                <th>اسم العرض</th>
                <th>الوصف</th>
                <th>نوع العرض</th>
                <th>تاريخ التحميل</th>
                <th>الإجراء</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredOffers.map((offer, index) => (
                <tr key={offer.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={offer.ImageId}
                      alt={offer.offername}
                      className="product-image"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </td>
                  <td>{offer.offername}</td>
                  <td>{offer.overdescription}</td>
                  <td>{offer.offertype}</td>
                  <td>{offer.dateupload}</td>
                  <td>
                    <div className="icon-container">
                      <button
                        className="icon-button"
                        onClick={() =>
                          navigate(`/dashboard/edit-offer/${offer.id}`)
                        }
                      >
                        <FontAwesomeIcon icon={faEdit} title="تعديل العرض" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="icon-container">
                      <button
                        className="icon-button"
                        onClick={() => handleOpenModal(offer.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} title="حذف العرض" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ✅ **مودال تأكيد الحذف** */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "white",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            هل أنت متأكد من أنك تريد حذف هذا العرض؟
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => selectedOfferId && handleDelete(selectedOfferId)}
            >
              نعم، احذف العرض
            </Button>
            <Button variant="outlined" onClick={handleCloseModal}>
              إلغاء
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default OffersList;
